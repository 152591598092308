import { API } from '../constants'
import { replaceParamsUri } from '../helpers/apiHelpers'
import {
	GET_PATIENT_COOS,
	GET_PATIENT_COOS_SUCCESS,
	GET_PATIENT_COOS_FAIL,
	patientTypes
} from './types'

export const initPatientInfo = patient => {
	return {
		type: patientTypes.initPatientInfo,
		patient
	}
}

export const getPatientCoos = customerId => ({
	type: GET_PATIENT_COOS,
	payload: {
		request: {
			method: 'GET',
			url: replaceParamsUri(
				['customerId'],
				[customerId],
				API.GET_CIRCLE_OF_OWN_SITUATION
			)
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: GET_PATIENT_COOS_SUCCESS,
					payload: response.data
				})
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: GET_PATIENT_COOS_FAIL,
					payload: error?.response?.data?.message,
					error: error
				})
			}
		}
	}
})
