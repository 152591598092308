import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Actions
import { userActions } from '../../actions'

// Assets
import alertIcon from '../../assets/icons/alert.svg'
import closeIcon from '../../assets/icons/x.svg'
import reloadIcon from '../../assets/icons/reload.svg'

// Reducers
import { userSelectors } from '../../reducers'

const UserErrorBox = () => {
	const dispatch = useDispatch()

	const userErrors = useSelector(state =>
		userSelectors.getUserErrors(state.user)
	)

	const handleReload = () => {
		window.location.reload()
	}

	return (
		<div className='user-error-box'>
			<div className='user-error-box__button-container flex'>
				<button
					className='btn btn--transparent user-error-box__close'
					onClick={() => handleReload()}
				>
					<img
						className='user-error-box__close-icon'
						src={reloadIcon}
						alt='X'
					/>
				</button>
				<button
					className='btn btn--transparent user-error-box__close'
					onClick={() => dispatch(userActions.resetUserErrors())}
				>
					<img className='user-error-box__close-icon' src={closeIcon} alt='X' />
				</button>
			</div>

			<img className='user-error-box__image' src={alertIcon} alt='!' />
			<ul className='user-error-box__list'>
				{userErrors.map((item, index) => (
					<li key={index} className='user-error-box__list-item'>
						{item}
					</li>
				))}
			</ul>
		</div>
	)
}
export default UserErrorBox
