/**
 * Configure redux store
 *
 * @author trung <trung@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import storage from 'redux-persist/lib/storage'
import { routerMiddleware } from 'connected-react-router'
import axios from 'axios'
import axiosMiddleware, { multiClientMiddleware } from 'redux-axios-middleware'

import { createLogger } from 'redux-logger'
import { userActions, notificationActions } from 'actions'
import { rootReducer, history } from './reducers'
import { PERSIST_KEY, backendUri, ROUTER_PATH } from './constants'
import moment from 'moment'

// Create client alias
// Used in action creators
const baseURL = backendUri
const client = {
	default: {
		client: axios.create({
			baseURL: baseURL,
			responseType: 'json'
		})
	}
}

// Config redux-persist
const persistConfig = {
	key: PERSIST_KEY,
	storage,
	blacklist: ['form', 'router', 'error', 'login', 'notification']
}
const persistedReducer = persistReducer(persistConfig, rootReducer)
// const persistedReducer = persistReducer(rootReducer)

const options = {
	returnRejectedPromiseOnError: true,
	interceptors: {
		request: [
			({}, config) => {
				const { token, refreshToken } = store.getState().user
				if (token) {
					config.headers['Authorization'] = `Bearer ${token}`
					config.headers['x-refresh'] = refreshToken
				}
				return config
			},

			async function({}, req) {
				if (sessionStorage.getItem('idleTime')) {
					sessionStorage.setItem(
						'idleTime',
						Number(moment(new Date()).add(process.env.IDLE_TIME, 'minutes'))
					)
				}

				if (sessionStorage.getItem('refreshTokenRefresh') !== null) {
					if (
						sessionStorage.getItem('refreshTokenRefresh') < Number(new Date())
					) {
						sessionStorage.setItem(
							'refreshTokenRefresh',
							Number(
								moment(new Date()).add(
									process.env.JWT_REFRESH_EXPIRATION_TIME,
									'minutes'
								)
							)
						)

						const { refreshToken } = store.getState().user

						try {
							const res = await store.dispatch(
								userActions.getNewRefreshToken(refreshToken)
							)
							if (res) {
								return req
							}
						} catch (error) {}
					}
				}

				return req
			}
		],
		response: [
			{
				success: ({}, response) => {
					return response
				},
				error: async ({}, error) => {
					const { data, config, status } = error.response
					const { refreshToken } = store.getState().user

					if (status === 401 && data?.code === 1003) {
						try {
							const res = await store.dispatch(
								userActions.getNewAccessToken(refreshToken)
								// console.log('refresh token ' + refreshToken)
							)
							if (res) {
								// New request with new token
								config.headers[
									'Authorization'
								] = `Bearer ${res.payload.data.accessToken}`
								return new Promise((resolve, reject) => {
									axios
										.request(config)
										.then(response => {
											resolve(response)
										})
										.catch(error => {
											reject(error)
										})
								})
							}
						} catch (e) {
							return Promise.reject(error).then(
								store.dispatch(userActions.signOut()).then(
									store.dispatch(
										notificationActions.showNotification({
											type: 'error',
											message: 'Logging out due inactivity',
											duration: null
										})
									)
								)
							)
						}
					} else {
						// console.log(error.response)
						return Promise.reject(error)
					}
					return Promise.reject(error)
				}
			}
		]
	}
}

// Define middleware to use
const middleware = [
	thunk,
	routerMiddleware(history),
	multiClientMiddleware(client, options)
	// axiosMiddleware
]
const isProduction = process.env.NODE_ENV === 'production'
if (!isProduction) {
	const logger = createLogger()
	middleware.push(logger)
}

const tools = [applyMiddleware(...middleware)]
if (window.__REDUX_DEVTOOLS_EXTENSION__) {
	tools.push(window.__REDUX_DEVTOOLS_EXTENSION__())
}

// Create redux store
// const store = createStore(persistedReducer, compose(...tools))
// const persistor = persistStore(store)

// export { store, persistor }

const store = createStore(persistedReducer, compose(...tools))
// const persistor = persistStore(store)

export { store }
