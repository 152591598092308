import { treatmentPlanTypes } from './types'

export const getTreatmentPlanById = customerId => {
	return {
		type: treatmentPlanTypes.getTreatmentPlanById,
		payload: {
			request: {
				method: 'GET',
				url: `treatment_plans/customer/${customerId}`
			}
		}
	}
}

export const createNewTreatmentPlan = data => {
	return {
		type: treatmentPlanTypes.createNewTreatmentPlan,
		payload: {
			request: {
				method: 'POST',
				url: '/treatment_plans',
				data
			}
		}
	}
}

export const updateTreatmentPlan = (treatmentPlanId, data) => {
	return {
		type: treatmentPlanTypes.updateTreatmentPlan,
		payload: {
			request: {
				method: 'PATCH',
				url: `/treatment_plans/${treatmentPlanId}`,
				data
			}
		}
	}
}

export const removeTreatmentPlan = (treatmentPlanId) => {
	return {
		type: treatmentPlanTypes.deleteTreatmentPlan,
		payload: {
			request: {
				method: 'DELETE',
				url: `/treatment_plans/${treatmentPlanId}/delete`
			}
		}
	}
}
