import React, { lazy } from 'react'
import { useSelector } from 'react-redux'

import { Switch, Redirect, BrowserRouter, Route } from 'react-router-dom'
import ProtectedRoute from '../shared/components/auth/ProtectedRoute'
import { ROUTER_PATH, USER_ROLE } from '../constants'
import AuthorizedLayout from '../shared/components/layout/AuthorizedLayout'
import UnauthorizedLayout from '../shared/components/layout/UnauthorizedLayout'
import { useAuthorization } from '../hooks'
import Snackbar from '../shared/components/Snackbar'
// Code splitting and lazy loading

const Login = lazy(() =>
	import(/* webpackChunkName: "Login" */ './pages/Login')
)
const SignUp = lazy(() =>
	import(/* webpackChunkName: "SignUp" */ './pages/SignUp')
)
const ResetPassword = lazy(() =>
	import(/* webpackChunkName: "ResetPassword" */ './pages/ResetPassword')
)
const ForgetPassword = lazy(() =>
	import(/* webpackChunkName: "ForgetPassword" */ './pages/ForgetPassword')
)

const Home = lazy(() => import(/* webpackChunkName: "Home" */ './pages/Home'))
const Profile = lazy(() =>
	import(/* webpackChunkName: "Home" */ './pages/Profile')
)
const OwnStatus = lazy(() =>
	import(/* webpackChunkName: "Home" */ './pages/OwnStatus')
)
const AuthorizedPersonnel = lazy(() =>
	import(/* webpackChunkName: "Home" */ './pages/AuthorizedPersonnel')
)
const Appointment = lazy(() =>
	import(/* webpackChunkName: "Appointment" */ './pages/Appointment')
)
const NewAppointment = lazy(() =>
	import(/* webpackChunkName: "NewAppointment" */ './pages/NewAppointment')
)
const PreInfoReminder = lazy(() =>
	import(/* webpackChunkName: "PreInfoReminder" */ './pages/PreInfoReminder')
)
const PreInfoForm = lazy(() =>
	import(/* webpackChunkName: "PreInfoForm" */ './pages/PreInfoForm')
)

const TreatmentPlan = lazy(() =>
	import(
		/* webpackChunkName: "TreatmentPlan" */ './pages/treatmentPlan/TreatmentPlan'
	)
)
const TreatmentPlanDetails = lazy(() =>
	import(
		/* webpackChunkName: "TreatmentPlanDetails" */ './pages/treatmentPlan/TreatmentPlanDetails'
	)
)
const TreatmentNotesDetails = lazy(() => 
	import(
		/* webpackChunkName: "TreatmentPlanDetails" */ './pages/treatmentNotes/TreatmentNotesDetails'
	)
)
const Notification = lazy(() =>
	import(/* webpackChunkName: "Notification" */ './pages/Notification')
)
const EditTreatmentNotes = lazy(() => 
	import(
		/* webpackChunkName: "EditTreatmentNotes" */ './pages/treatmentNotes/EditTreatmentNotes'
	)
)
const NewTreatmentNotes = lazy(() => 
	import(
		/* webpackChunkName: "NewTreatmentNotes" */ './pages/treatmentNotes/NewTreatmentNotes'
	)
)
const NewTreatmentPlan = lazy(() =>
	import(
		/* webpackChunkName: "NewTreatmentPlan" */ './pages/treatmentPlan/NewTreatmentPlan'
	)
)
const Calendar = lazy(() =>
	import(/* webpackChunkName: "Calendar" */ './pages/Calendar')
)
const CalendarAddService = lazy(() =>
	import(
		/* webpackChunkName: "CalendarAddService" */ './pages/CalendarAddService'
	)
)
const CalendarBooking = lazy(() =>
	import(/* webpackChunkName: "CalendarBooking" */ './pages/CalendarBooking')
)
const Patients = lazy(() =>
	import(/* webpackChunkName: "Patients" */ './pages/patients/Patients')
)
const PatientDetails = lazy(() =>
	import(
		/* webpackChunkName: "PatientDetails" */ './pages/patients/PatientDetails'
	)
)
const BaziCalendar = lazy(() =>
	import(
		/* webpackChunkName: "BaziCalendar" */ './pages/BaziCalendar/BaziCalendar'
	)
)
const SetUpFA = lazy(() =>
	import(
		/* webpackChunkName: "SetUpFA" */ './pages/SetUpFA'
	)
)
const ForgetTwoFa = lazy(() => 
	import(
		/* webpackChunkName: "ForgetTwoFa" */ './pages/ForgetTwoFa'
	)
)
const Router = () => {
	const { isAuthenticated } = useAuthorization()
	const user = useSelector(state => state.user)
	const authenticatedRoutesCustomer = (
		<AuthorizedLayout>
			<Switch>
				<ProtectedRoute
					exact
					path={ROUTER_PATH.HOME}
					component={Home}
					title='Home'
				/>
				<ProtectedRoute
					exact
					path={ROUTER_PATH.PROFILE}
					component={Profile}
					title='Profile'
				/>
				<ProtectedRoute
					exact
					path={ROUTER_PATH.OWN_STATUS}
					component={OwnStatus}
					title='Own Status'
				/>
				<ProtectedRoute
					exact
					path={ROUTER_PATH.AUTHORIZED_PERSONNEL}
					component={AuthorizedPersonnel}
					title='Authorized Personnel'
				/>
				<ProtectedRoute
					exact
					path={ROUTER_PATH.APPOINTMENT}
					component={Appointment}
					title='Appointment'
				/>
				<ProtectedRoute
					exact
					path={ROUTER_PATH.APPOINTMENT_NEW}
					component={NewAppointment}
					title='New Appointment'
				/>
				<ProtectedRoute
					exact
					path={ROUTER_PATH.PRE_INFO_REMINDER}
					component={PreInfoReminder}
					title='Pre Info Reminder'
				/>
				<ProtectedRoute
					exact
					path={ROUTER_PATH.PRE_INFO_FORM}
					component={PreInfoForm}
					title='Pre Info Form'
				/>
				<ProtectedRoute
					exact
					path={ROUTER_PATH.TREATMENT_PLAN}
					component={TreatmentPlan}
					title='Treatment plan'
				/>

				<ProtectedRoute
					exact
					path={ROUTER_PATH.NOTIFICATION}
					component={Notification}
					title='Notification'
				/>

				<Redirect to={ROUTER_PATH.HOME} />
			</Switch>
		</AuthorizedLayout>
	)
	const authenticatedRoutesServiceProvider = (
		<AuthorizedLayout>
			<Switch>
				<ProtectedRoute
					exact
					path={ROUTER_PATH.HOME}
					component={Home}
					title='Home'
				/>
				<ProtectedRoute
					exact
					path={ROUTER_PATH.PROFILE}
					component={Profile}
					title='Profile'
				/>
				<ProtectedRoute
					exact
					path={ROUTER_PATH.CALENDAR}
					component={Calendar}
					title='Calendar'
				/>
				<ProtectedRoute
					exact
					path={ROUTER_PATH.APPOINTMENT}
					component={Appointment}
					title='Appointment'
				/>
				<ProtectedRoute
					exact
					path={ROUTER_PATH.CALENDAR_ADD_SERVICE}
					component={CalendarAddService}
					title='Calendar Add Service'
				/>
				<ProtectedRoute
					exact
					path={ROUTER_PATH.CALENDAR_BOOKING}
					component={CalendarBooking}
					title='Calendar Booking'
				/>
				<ProtectedRoute
					exact
					path={ROUTER_PATH.PATIENTS}
					component={Patients}
					title='Patients'
				/>
				<ProtectedRoute
					exact
					path={ROUTER_PATH.PATIENT_DETAILS}
					component={PatientDetails}
					title='Patients Details'
				/>
				<ProtectedRoute
					exact
					path={ROUTER_PATH.TREATMENT_NOTES_UPDATE}
					component={EditTreatmentNotes}
					title='Edit Treatment Notes'
				/>
				<ProtectedRoute
					exact
					path={ROUTER_PATH.TREATMENT_NOTES_NEW}
					component={NewTreatmentNotes}
					title='New Treatment Notes'
				/>
				<ProtectedRoute
					exact
					path={ROUTER_PATH.TREATMENT_NOTES_DETAILS}
					component={TreatmentNotesDetails}
					title='Treatment Notes Details'
				/>
				<ProtectedRoute
					exact
					path={ROUTER_PATH.TREATMENT_PLAN_NEW}
					component={NewTreatmentPlan}
					title='New Treatment Plan'
				/>
				<ProtectedRoute
					exact
					path={ROUTER_PATH.TREATMENT_PLAN_DETAILS}
					component={TreatmentPlanDetails}
					title='Treatment Plan Details'
				/>{' '}
				<ProtectedRoute
					exact
					path={ROUTER_PATH.BAZI_CALENDAR}
					component={BaziCalendar}
					title='Bazi Calendar'
				/>
				<ProtectedRoute
					exact
					path={ROUTER_PATH.PATIENT_DETAILS_PREINFO_FORM}
					component={PreInfoForm}
					title='Pre Info Form'
				/>
				<Redirect to={ROUTER_PATH.HOME} />
			</Switch>
			<Snackbar />
		</AuthorizedLayout>
	)
	const authenticatedRoutesAdmin = (
		<AuthorizedLayout>
			<Switch>
				<ProtectedRoute
					exact
					path={ROUTER_PATH.HOME}
					component={Home}
					title='Home'
				/>

				<Redirect to={ROUTER_PATH.HOME} />
			</Switch>
			<Snackbar />
		</AuthorizedLayout>
	)
	const unauthenticatedRoutes = (
		<UnauthorizedLayout>
			<Switch>
				<Route exact path={ROUTER_PATH.LOGIN} component={Login} title='Login' />
				<Route
					exact
					path={ROUTER_PATH.SIGNUP}
					component={SignUp}
					title='SignUp'
				/>
				<Route
					exact
					path={ROUTER_PATH.TWOFA}
					component={SetUpFA}
					title='Setup 2FA'
				/>
				<Route
					exact
					path={ROUTER_PATH.FORGOT2FA}
					component={ForgetTwoFa}
					title='Forgot 2FA'
				/>
				<Route
					exact
					path={ROUTER_PATH.FORGET_PASSWORD}
					component={ForgetPassword}
					title='Forgot Password'
				/>
				<Route
					exact
					path={ROUTER_PATH.RESET_PASSWORD}
					component={ResetPassword}
					title='Reset Password'
				/>

				<Redirect to={ROUTER_PATH.LOGIN} />
			</Switch>
		</UnauthorizedLayout>
	)

	const routeObject = {
		USER: authenticatedRoutesCustomer,
		SERVICE_PROVIDER: authenticatedRoutesServiceProvider,
		ADMIN: authenticatedRoutesAdmin
	}

	const content = !!isAuthenticated
		? routeObject[user.role]
		: unauthenticatedRoutes
	return !!user.user ? (
		<BrowserRouter>{content}</BrowserRouter>
	) : !!!isAuthenticated && !!!user.user ? (
		<BrowserRouter>{content}</BrowserRouter>
	) : null
}

export default Router
