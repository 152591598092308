/**
 *
 * @author Trung H <trung@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */
import {
	SIGN_IN_SUCCESS,
	SIGN_IN_FAIL,
	SIGN_OUT_FAIL,
	SIGN_OUT,
	GET_USER_BY_ID_SUCCESS,
	UPDATE_USER_SUCCESS,
	UPDATE_ROLE,
	GET_USER_BY_ID,
	UPDATE_USER,
	AUTHORIZE_PERSON_SUCCESS,
	AUTHORIZE_PERSON,
	GET_BOOKINGS,
	GET_BOOKINGS_SUCCESS,
	GET_BOOKINGS_FAIL,
	GET_TREATMENT_PLANS,
	GET_TREATMENT_PLANS_FAIL,
	GET_TREATMENT_PLANS_SUCCESS,
	GET_CALENDAR_FAIL,
	GET_CALENDAR,
	GET_CALENDAR_SUCCESS,
	GET_WEEK_CALENDAR,
	GET_WEEK_CALENDAR_SUCCESS,
	GET_WEEK_CALENDAR_FAIL,
	UPDATE_CALENDAR,
	UPDATE_CALENDAR_FAIL,
	CREATE_CALENDAR,
	CREATE_CALENDAR_FAIL,
	GET_ALL_BOOKINGS_FAIL,
	GET_ALL_BOOKINGS,
	GET_ALL_BOOKINGS_SUCCESS,
	GET_COMPANY_SERVICES_SUCCESS,
	GET_USERS_BY_ROLE_FAIL,
	GET_USERS_BY_ROLE,
	GET_USERS_BY_ROLE_SUCCESS,
	GET_PRE_INFO_FAIL,
	GET_PRE_INFO,
	GET_PRE_INFO_SUCCESS,
	SEND_PRE_INFO_FAIL,
	SEND_PRE_INFO,
	SEND_PRE_INFO_SUCCESS,
	UPDATE_PRE_INFO_FAIL,
	UPDATE_PRE_INFO,
	UPDATE_PRE_INFO_SUCCESS,
	UPDATE_USER_PASSWORD_FAIL,
	UPDATE_USER_PASSWORD,
	UPDATE_USER_PASSWORD_SUCCESS,
	REMOVE_BOOKING_FAIL,
	REMOVE_BOOKING,
	REMOVE_BOOKING_SUCCESS,
	GET_TREATMENT_PLANS_CUSTOMER_FAIL,
	GET_TREATMENT_PLANS_CUSTOMER,
	GET_TREATMENT_PLANS_CUSTOMER_SUCCESS,
	GET_AVAILABLE_BOOKINGS_FAIL,
	GET_AVAILABLE_BOOKINGS,
	GET_AVAILABLE_BOOKINGS_SUCCESS,
	GET_ALLAVAILABLE_BOOKINGS_FAIL,
	GET_ALLAVAILABLE_BOOKINGS,
	GET_ALLAVAILABLE_BOOKINGS_SUCCESS,
	RESET_AVAILABLE_BOOKING,
	CREATE_BOOKING_FAIL,
	EDIT_BOOKING_FAIL,
	EDIT_BOOKING_STATUS_FAIL,
	GET_COMPANIES_FAIL,
	GET_COMPANY_SERVICES_FAIL,
	RESET_USER_ERRORS,
	GET_CIRCLE_OF_OWN_SITUATION_FAIL,
	GET_CIRCLE_OF_OWN_SITUATION,
	GET_CIRCLE_OF_OWN_SITUATION_SUCCESS,
	CREATE_CIRCLE_OF_OWN_SITUATION_FAIL,
	CREATE_CIRCLE_OF_OWN_SITUATION,
	CREATE_CIRCLE_OF_OWN_SITUATION_SUCCESS,
	CREATE_LOCATION_FAIL,
	CREATE_LOCATION,
	CREATE_LOCATION_SUCCESS,
	CREATE_SERVICE,
	CREATE_SERVICE_FAIL,
	CREATE_SERVICE_SUCCESS,
	EDIT_SERVICE_FAIL,
	EDIT_SERVICE,
	EDIT_SERVICE_SUCCESS,
	UPLOAD_USER_AVATAR,
	UPLOAD_USER_AVATAR_SUCCESS,
	COPY_CALENDAR_FAIL,
	COPY_CALENDAR,
	COPY_CALENDAR_SUCCESS,
	DEACTIVATE_PROVIDER_FAIL,
	DEACTIVATE_PROVIDER,
	DEACTIVATE_PROVIDER_SUCCESS,
	GET_NEW_ACCESS_TOKEN_SUCCESS,
	GET_NEW_REFRESH_TOKEN_SUCCESS,
	GET_CONSENTED_PROVIDERS_FAIL,
	GET_CONSENTED_PROVIDERS,
	GET_CONSENTED_PROVIDERS_SUCCESS,
	AUTHORIZE_PERSON_FAIL,
	EDIT_BOOKING,
	GET_PROVIDERS_ADMIN_FAIL,
	GET_PROVIDERS_ADMIN,
	GET_PROVIDERS_ADMIN_SUCCESS,
	UPDATE_COMPANY,
	UPDATE_COMPANY_FAIL,
	UPDATE_COMPANY_SUCCESS,
	UPDATE_USER_FAIL,
	twoFAtypes
} from '../actions/types'
import { USER_ROLE } from '../constants'

const initialState = {
	token: null,
	refreshToken: null,
	twoFa: null,
	allBookings: [],
	availableBookings: [],
	allAvailableBookings: [],
	bookings: null,
	calendar: null,
	consentedProviders: [],
	coos: [],
	loading: false,
	error: false,
	userErrors: [],
	signInError: null,
	user: null,
	role: USER_ROLE.USER,
	myServices: null,
	patients: [],
	serviceProviders: [],
	notifications: [],
	preInfo: null,
	treatmentPlans: [],
	treatmentPlansCustomer: []
}

export const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case AUTHORIZE_PERSON_FAIL:
		case COPY_CALENDAR_FAIL:
		case CREATE_CALENDAR_FAIL:
		case CREATE_CIRCLE_OF_OWN_SITUATION_FAIL:
		case CREATE_LOCATION_FAIL:
		case GET_AVAILABLE_BOOKINGS_FAIL:
		case GET_BOOKINGS_FAIL:
		case GET_ALL_BOOKINGS_FAIL:
		case GET_CIRCLE_OF_OWN_SITUATION_FAIL:
		case GET_CONSENTED_PROVIDERS_FAIL:
		case GET_PROVIDERS_ADMIN_FAIL:
		case GET_TREATMENT_PLANS_FAIL:
		case GET_TREATMENT_PLANS_CUSTOMER_FAIL:
		case GET_USERS_BY_ROLE_FAIL:
		case GET_CALENDAR_FAIL:
		case GET_PRE_INFO_FAIL:
		case SEND_PRE_INFO_FAIL:
		case UPDATE_CALENDAR_FAIL:
		case UPDATE_COMPANY_FAIL:
		case UPDATE_PRE_INFO_FAIL:
		case UPDATE_USER_FAIL:
		case UPDATE_USER_PASSWORD_FAIL:
		// Admin
		case DEACTIVATE_PROVIDER_FAIL:
		// Appointment
		case CREATE_BOOKING_FAIL:
		case CREATE_SERVICE_FAIL:
		case EDIT_BOOKING_FAIL:
		case EDIT_BOOKING_STATUS_FAIL:
		case EDIT_SERVICE_FAIL:
		case GET_COMPANIES_FAIL:
		case GET_COMPANY_SERVICES_FAIL:
		case REMOVE_BOOKING_FAIL:
			return {
				...state,
				loading: false
				// userErrors: [...state.userErrors, action.payload]
			}
		case GET_NEW_ACCESS_TOKEN_SUCCESS:
			return {
				...state,
				token: action.payload.data.accessToken
			}
		case GET_NEW_REFRESH_TOKEN_SUCCESS:
			return {
				...state,
				refreshToken: action.payload.data.refreshedToken
			}
		case SIGN_IN_SUCCESS:
			// localStorage.setItem('token', action.payload.data.token)

			// return {
			// 	...state,
			// 	user: { ...action.payload.data.user },
			// 	role: action.payload.data.user.role,
			// 	loading: false
			// }
			const { token, refreshToken } = action.payload.data
			return {
				...state,
				// user,
				token: token,
				refreshToken: refreshToken,
				user: { ...action.payload.data.user },
				role: action.payload.data.user.role,
				loading: false
				// isAdmin: user.role === 'admin'
			}

		case SIGN_IN_FAIL:
			return {
				...state,
				loading: false,
				error: action.error.data
					? action.error.data.message
					: 'Kirjautumisessa tapahtui virhe'
			}

		case SIGN_OUT_FAIL:
			return {
				...state,
				loading: false
			}
		case twoFAtypes.createTwoFASuccess:
			return {
				...state,
				twoFa: action.payload.data
			}
		case GET_USER_BY_ID:
			return {
				...state,
				loading: true
			}
		case GET_USER_BY_ID_SUCCESS: {
			return {
				...state,
				user: { ...action.payload.data },
				role: action.payload.data.role,
				loading: false
			}
		}

		case RESET_USER_ERRORS: {
			return {
				...state,
				userErrors: []
			}
		}

		case GET_PRE_INFO: {
			return {
				...state,
				loading: true,
				preInfo: null
			}
		}

		case COPY_CALENDAR:
		case CREATE_CALENDAR:
		case CREATE_CIRCLE_OF_OWN_SITUATION:
		case CREATE_LOCATION:
		case CREATE_SERVICE:
		case EDIT_BOOKING:
		case EDIT_SERVICE:
		case GET_AVAILABLE_BOOKINGS:
		case UPDATE_USER:
		case AUTHORIZE_PERSON:
		case GET_ALL_BOOKINGS:
		case GET_CIRCLE_OF_OWN_SITUATION:
		case GET_CONSENTED_PROVIDERS:
		case GET_PROVIDERS_ADMIN:
		case GET_TREATMENT_PLANS:
		case GET_TREATMENT_PLANS_CUSTOMER:
		case GET_USERS_BY_ROLE:
		case GET_CALENDAR:
		case REMOVE_BOOKING:
		case SEND_PRE_INFO:
		case UPDATE_CALENDAR:
		case UPDATE_COMPANY:
		case UPDATE_PRE_INFO:
		case UPDATE_USER_PASSWORD:
		// Admin
		case DEACTIVATE_PROVIDER:
			return {
				...state,
				loading: true
			}
		case GET_BOOKINGS:
			return {
				...state,
				bookings: null,
				loading: true
			}

		case UPDATE_USER_SUCCESS: {
			return {
				...state,
				user: {
					...state.user,
					...action.payload
				},
				loading: false
			}
		}

		case CREATE_CIRCLE_OF_OWN_SITUATION_SUCCESS: {
			return {
				...state,
				coos: [...state.coos, action.payload]?.sort((a, b) =>
					new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1
				),
				loading: false
			}
		}

		case CREATE_LOCATION_SUCCESS: {
			const locations = [...state.user.company.locations, action.payload]
			return {
				...state,
				user: {
					...state.user,
					company: {
						...state.user.company,
						locations: locations
					}
				},
				loading: false
			}
		}

		case CREATE_SERVICE_SUCCESS: {
			return {
				...state,
				myServices: [...state.myServices, action.payload],
				loading: false
			}
		}

		case GET_AVAILABLE_BOOKINGS_SUCCESS: {
			return {
				...state,
				availableBookings: action.payload,
				loading: false
			}
		}
		case GET_ALLAVAILABLE_BOOKINGS_SUCCESS: {
			return {
				...state,
				allAvailableBookings: action.payload,
				loading: false
			}
		}
		case GET_BOOKINGS_SUCCESS: {
			return {
				...state,
				bookings: action.payload,
				loading: false
			}
		}
		case GET_ALL_BOOKINGS_SUCCESS: {
			return {
				...state,
				allBookings: action.payload,
				loading: false
			}
		}
		case GET_CALENDAR_SUCCESS: {
			return {
				...state,
				calendar: action.payload,
				loading: false
			}
		}
		case GET_WEEK_CALENDAR_SUCCESS: {
			return {
				...state,
				calendar: action.payload,
				loading: false
			}
		}
		case GET_CONSENTED_PROVIDERS_SUCCESS: {
			return {
				...state,
				consentedProviders: action.payload.data,
				loading: false
			}
		}
		case GET_TREATMENT_PLANS_SUCCESS: {
			return {
				...state,
				treatmentPlans: action.payload,
				loading: false
			}
		}

		case GET_TREATMENT_PLANS_CUSTOMER_SUCCESS: {
			return {
				...state,
				treatmentPlansCustomer: action.payload,
				loading: false
			}
		}

		case GET_COMPANY_SERVICES_SUCCESS: {
			if (!!state.user && state.user.role === 'SERVICE_PROVIDER') {
				return {
					...state,
					myServices: action.payload,
					loading: false
				}
			}
			return {
				...state
			}
		}

		case GET_CIRCLE_OF_OWN_SITUATION_SUCCESS: {
			return {
				...state,
				coos: action.payload.sort((a, b) =>
					new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1
				),
				loading: false
			}
		}

		case GET_USERS_BY_ROLE_SUCCESS: {
			if (action.role === 'USER') {
				return {
					...state,
					patients: action.payload,
					loading: false
				}
			}
			return {
				...state,
				serviceProviders: action.payload,
				loading: false
			}
		}

		case GET_PRE_INFO_SUCCESS: {
			return {
				...state,
				preInfo: action.payload,
				loading: false
			}
		}

		case UPLOAD_USER_AVATAR_SUCCESS: {
			return {
				...state,
				user: { ...state.user, avatar: action.payload.data }
			}
		}
		case RESET_AVAILABLE_BOOKING:
			return {
				...state,
				availableBookings: []
			}

		case UPDATE_PRE_INFO_SUCCESS: {
			return {
				...state,
				preInfo: null,
				loading: false
			}
		}

		case UPDATE_ROLE: {
			return { ...state, role: action.role }
		}

		case AUTHORIZE_PERSON_SUCCESS:
		case COPY_CALENDAR_SUCCESS:
		case EDIT_SERVICE_SUCCESS:
		case GET_PROVIDERS_ADMIN_SUCCESS:
		case REMOVE_BOOKING_SUCCESS:
		case SEND_PRE_INFO_SUCCESS:
		case UPDATE_COMPANY_SUCCESS:
		case UPDATE_USER_PASSWORD_SUCCESS:
		// Admin
		case DEACTIVATE_PROVIDER_SUCCESS: {
			return {
				...state,
				loading: false
			}
		}
		case SIGN_OUT:
			return initialState
		default:
			return state
	}
}

export const userSelectors = {
	getAllAvailableBookings: (state = initialState) => state.allAvailableBookings,
	getAvailableBookings: (state = initialState) => state.availableBookings,
	getBookings: (state = initialState) => state.bookings,
	getAllBookings: (state = initialState) => state.allBookings,
	getCalendar: (state = initialState) => state.calendar,
	getWeekCalendar: (state = initialState) => state.calendar,
	getConsentedProviders: (state = initialState) => state.consentedProviders,
	getCoos: (state = initialState) => state.coos,
	getLocations: (state = initialState) =>
		!!state.user ? state.user.company.locations : [],
	getUser: (state = initialState) => state.user,
	getUserErrors: (state = initialState) => state.userErrors,
	getRole: (state = initialState) => state.role,
	getLoading: (state = initialState) => state.loading,
	getMyServices: (state = initialState) => state.myServices,
	getPatients: (state = initialState) => state.patients,
	getNotifications: (state = initialState) => state.notifications,
	getPreInfo: (state = initialState) => state.preInfo,
	getServiceProviders: (state = initialState) => state.serviceProviders,
	getTreatmentPlans: (state = initialState) => state.treatmentPlans,
	getCustomerTreatmentPlans: (state = initialState) =>
		state.treatmentPlansCustomer,
	getTwoFAInfo: (state = initialState) => state.twoFa
}
