/**
 *
 * @author Jere Rantalainen <jere@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */
import {
	CREATE_BOOKING,
	CREATE_BOOKING_FAIL,
	CREATE_BOOKING_SUCCESS,
	CREATE_SERVICE,
	CREATE_SERVICE_FAIL,
	CREATE_SERVICE_SUCCESS,
	EDIT_BOOKING,
	EDIT_BOOKING_FAIL,
	EDIT_BOOKING_STATUS,
	EDIT_BOOKING_STATUS_FAIL,
	EDIT_BOOKING_STATUS_SUCCESS,
	EDIT_BOOKING_SUCCESS,
	EDIT_SERVICE,
	EDIT_SERVICE_FAIL,
	EDIT_SERVICE_SUCCESS,
	GET_COMPANIES,
	GET_COMPANIES_FAIL,
	GET_COMPANIES_SUCCESS,
	GET_COMPANY_SERVICES,
	GET_COMPANY_SERVICES_FAIL,
	GET_COMPANY_SERVICES_SUCCESS,
	REMOVE_BOOKING,
	REMOVE_BOOKING_FAIL,
	REMOVE_BOOKING_SUCCESS
} from './types'
import { API } from '../constants'
import { replaceParamsUri } from '../helpers/apiHelpers'
import { userActions } from '.'

export const getCompanies = () => ({
	type: GET_COMPANIES,
	payload: {
		request: {
			method: 'GET',
			url: API.GET_COMPANIES
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: GET_COMPANIES_SUCCESS,
					payload: response.data
				})
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: GET_COMPANIES_FAIL,
					payload: error?.response?.data?.message,
					error: error
				})
			}
		}
	}
})

export const createService = (companyId, data) => ({
	type: CREATE_SERVICE,
	payload: {
		request: {
			method: 'POST',
			url: replaceParamsUri(['companyId'], [companyId], API.CREATE_SERVICE),
			data: data
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: CREATE_SERVICE_SUCCESS,
					payload: response.data
				})
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: CREATE_SERVICE_FAIL,
					payload: error?.response?.data?.message,
					error: error
				})
			}
		}
	}
})

export const editService = (companyId, serviceId, data) => ({
	type: EDIT_SERVICE,
	payload: {
		request: {
			method: 'PATCH',
			url: replaceParamsUri(
				['companyId', 'serviceId'],
				[companyId, serviceId],
				API.EDIT_SERVICE
			),
			data: data
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: EDIT_SERVICE_SUCCESS,
					payload: response.data
				})
				dispatch(getCompanyServices(companyId))
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: EDIT_SERVICE_FAIL,
					payload: error?.response?.data?.message,
					error: error
				})
			}
		}
	}
})

export const getCompanyServices = companyId => ({
	type: GET_COMPANY_SERVICES,
	payload: {
		request: {
			method: 'GET',
			url: replaceParamsUri(
				['companyId'],
				[companyId],
				API.GET_COMPANY_SERVICES
			)
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: GET_COMPANY_SERVICES_SUCCESS,
					payload: response.data
				})
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: GET_COMPANY_SERVICES_FAIL,
					payload: error?.response?.data?.message,
					error: error
				})
			}
		}
	}
})

export const createBooking = data => ({
	type: CREATE_BOOKING,
	payload: {
		request: {
			method: 'POST',
			url: API.CREATE_BOOKING,
			data: data
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: CREATE_BOOKING_SUCCESS,
					payload: response.data
				})
				dispatch(userActions.getBookings())
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: CREATE_BOOKING_FAIL,
					payload: !!error.response
						? error?.response?.data?.message
						: 'Error while creating new booking.',
					error: error
				})
			}
		}
	}
})

export const editBooking = (bookingId, data) => ({
	type: EDIT_BOOKING,
	payload: {
		request: {
			method: 'PATCH',
			url: replaceParamsUri(['bookingId'], [bookingId], API.EDIT_BOOKING),
			data: data
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: EDIT_BOOKING_SUCCESS,
					payload: response.data
				})
				dispatch(userActions.getBookings())
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: EDIT_BOOKING_FAIL,
					payload: !!error.response
						? error?.response?.data?.message
						: 'Error while creating new booking.',
					error: error
				})
			}
		}
	}
})

export const removeBooking = bookingId => ({
	type: REMOVE_BOOKING,
	payload: {
		request: {
			method: 'DELETE',
			url: replaceParamsUri(['bookingId'], [bookingId], API.REMOVE_BOOKING)
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: REMOVE_BOOKING_SUCCESS,
					payload: response.data
				})
				dispatch(userActions.getBookings())
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: REMOVE_BOOKING_FAIL,
					payload: !!error.response
						? error?.response?.data?.message
						: 'Error while creating new booking.',
					error: error
				})
			}
		}
	}
})

export const editBookingStatus = (bookingId, status) => ({
	type: EDIT_BOOKING_STATUS,
	payload: {
		request: {
			method: 'PATCH',
			url:
				replaceParamsUri(['bookingId'], [bookingId], API.EDIT_BOOKING) +
				'/' +
				status
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: EDIT_BOOKING_STATUS_SUCCESS,
					payload: response.data
				})
				dispatch(userActions.getBookings())
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: EDIT_BOOKING_STATUS_FAIL,
					payload: !!error.response
						? error?.response?.data?.message
						: 'Error while editing the booking.',
					error: error
				})
			}
		}
	}
})
