import { treatmentPlanTypes } from '../actions/types'

const initialState = {
	treatmentPlan: [],
	error: null,
	loading: false
}

export const treatmentPlanReducer = (state = initialState, action) => {
	switch (action.type) {
		case treatmentPlanTypes.getTreatmentPlanById:
			return {
				...state,
				loading: true
			}
		case treatmentPlanTypes.getTreatmentPlanByIdSuccess:
			return {
				...state,
				treatmentPlan: action.payload.data,
				loading: false
			}

		// Create
		case treatmentPlanTypes.createNewTreatmentPlan:
			return {
				...state,
				loading: true
			}
		case treatmentPlanTypes.createNewTreatmentPlanSuccess:
			return {
				...state,
				loading: false
			}

		default:
			return state
	}
}
