/**
 * Protected route
 * Only let user access the route if user has been authenticated
 * Otherwise redirect user to login page
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { useAuthorization, authorizedState } from '../../../hooks'

// Wrap title and meta data to Component
const withHelmet = (component, title) => (
	<>
		<Helmet>
			<title>{title}</title>
			<meta name='description' content={`Akupunktioklinikka | ${title}`} />
		</Helmet>
		{component}
	</>
)

const ProtectedRoute = ({
	component: Component,
	title,
	unauthenticatedRedirect = '/login',
	unauthorizedRedirect = '/no-service',
	...rest
}) => {
	const { isAuthorized, isAuthenticated } = useAuthorization()
	if (!isAuthenticated) {
		return <Redirect to={unauthenticatedRedirect} />
	}

	switch (isAuthorized) {
		case authorizedState.idle:
			return null

		case authorizedState.pending:
			return <p>Loading...</p>

		case authorizedState.rejected:
			return <Redirect to={unauthorizedRedirect} />
	}

	return (
		<Route
			{...rest}
			render={props => withHelmet(<Component {...props} />, title)}
		/>
	)
}

export default ProtectedRoute
