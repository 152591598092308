import { companyTypes as types } from './types'

export const getServicesByCompany = companyId => {
	return {
		type: types.getServicesByCompany,
		payload: {
			request: {
				method: 'GET',
				url: `/companies/${companyId}/services`
			}
		}
	}
}

export const createCompanyService = (companyId, data) => {
	return {
		type: types.createNewTreatmentPlan,
		payload: {
			request: {
				method: 'POST',
				url: `/companies/${companyId}/services`,
				data
			}
		}
	}
}

export const updateCompanyInfo = (companyId, data) => {
	return {
		type: types.updateCompanyInfo,
		payload: {
			request: {
				method: 'PATCH',
				url: `/companies/${companyId}`,
				data
			}
		}
	}
}

export const updateCompanyService = (companyId, serviceId, data) => {
	return {
		type: types.updateCompanyService,
		payload: {
			request: {
				method: 'PATCH',
				url: `/companies/${companyId}/services/${serviceId}}`,
				data
			}
		}
	}
}
