import { notificationTypes as types } from '../actions/types'
import { normalizeError } from '../helpers/notificationError'
import { NOTIFICATION_TYPE, ERRORS, errorMessages } from '../constants'

const initialState = {
	notification: null
}
const normalizeMessage = error => {
	const errorMessage =
		!!error && !!error.response.data
			? !!errorMessages[error.response.data.code]
				? 'error_messages.' + errorMessages[error.response.data.code]
				: !!error.response.data.message
				? error.response.data.message
				: 'Sivustoa ladatessa  tapahtui virhe, päivitä sivu uudelleen'
			: 'Sivustoa ladatessa  tapahtui virhe, päivitä sivu uudelleen'
	const errorCode =
		!!error && error.response.data ? error.response.data.code : '000'
	const priorityErrors = [7002, 3002]
	if (priorityErrors.some(item => item === errorCode)) {
		return ERRORS[errorCode]
	}
	if (errorMessage) {
		return errorMessage
	}
	return ERRORS[errorCode]
}

export const notificationReducer = (state = initialState, action) => {
	if (action.type.endsWith('_FAIL')) {
		return {
			...state,
			notification: {
				type: NOTIFICATION_TYPE.error,
				message: normalizeMessage(action.error),
				duration: 4000
			}
		}
	} else if (action.type === 'CREATE_BOOKING_SUCCESS') {
		return {
			...state,
			notification: {
				type: NOTIFICATION_TYPE.success,
				message:
					'Varaus luotiin onnistuneesti, varaustiedot on lähetetty sinulle sähköpostilla.',
				duration: 4000
			}
		}
	}
	switch (action.type) {
		case types.showNotification:
			return { ...state, notification: action.notification }
		case types.resetNotification:
			return initialState
		default:
			return state
	}
}
