import { bookingTypes } from '../actions/types'

const initialState = {
	customerBooking: [],

	error: null,
	loading: false
}

export const customerBookingReducer = (state = initialState, action) => {
	switch (action.type) {
		case bookingTypes.getAllBookingByCustomerId:
			return {
				...state,
				loading: true
			}
		case bookingTypes.getAllBookingByCustomerIdSuccess:
			return {
				...state,
				customerBooking: action.payload.data,
				loading: false
			}

		default:
			return state
	}
}
