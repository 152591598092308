import { preInfoTypes } from '../actions/types'

const initialState = {
	preInfo: [],
	loading: false,
	error: null
}

export const preInfoReducer = (state = initialState, action) => {
	switch (action.type) {
		case preInfoTypes.getPreInfoByIdSuccess:
			return {
				...state,
				preInfo: action.payload.data
			}

		default:
			return state
	}
}
