/**
 * Root reducer
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'

import {
	GET_USER_BY_ID,
	GET_USER_BY_ID_SUCCESS,
	GET_USER_BY_ID_FAIL,
	SIGN_IN,
	SIGN_IN_SUCCESS,
	SIGN_IN_FAIL,
	SIGN_UP,
	SIGN_UP_SUCCESS,
	SIGN_UP_FAIL,
	FORGOT_PASSWORD,
	FORGOT_PASSWORD_SUCCESS,
	FORGOT_PASSWORD_FAIL,
	RESET_PASSWORD,
	RESET_PASSWORD_SUCCESS,
	RESET_PASSWORD_FAIL,
	UPDATE_USER
} from '../actions/types'

const history = createBrowserHistory()

// Login
// User
import { userReducer, userSelectors } from './user'

const loadingInitialState = {
	authLoading: true,
	appLoading: false
}

// Appointment
import { appointmentReducer, appointmentSelectors } from './appointment'
import { preInfoReducer } from './preInfo'
import { patientReducer } from './patient'
import { bookingReducer } from './booking'
import { treatmentPlanReducer } from './treatmentPlan'
import { notificationReducer } from './notification'
import { companyReducer } from './company'
import { adminReducer } from './admin'
import { treatmentNotesReducer } from './treatmentNotes'
import { customerBookingReducer } from './customerBooking'

const loadingReducer = (state = loadingInitialState, action) => {
	if (action.type.endsWith('_SUCCESS') || action.type.endsWith('_FAIL')) {
		switch (action.type) {
			case SIGN_UP_SUCCESS:
			case SIGN_IN_SUCCESS:
			case GET_USER_BY_ID_SUCCESS:
			case FORGOT_PASSWORD_SUCCESS:
			case RESET_PASSWORD_SUCCESS:
			case SIGN_UP_FAIL:
			case SIGN_IN_FAIL:
			case GET_USER_BY_ID_FAIL:
			case FORGOT_PASSWORD_FAIL:
			case RESET_PASSWORD_FAIL:
				return { ...state, authLoading: false }
			default:
				return { ...state, appLoading: false }
		}
	}
	switch (action.type) {
		case SIGN_UP:
		case SIGN_IN:
		case GET_USER_BY_ID:
		case FORGOT_PASSWORD:
		case RESET_PASSWORD:
			return { ...state, authLoading: true }
		case UPDATE_USER:
			return { ...state, appLoading: true }

		default:
			return state
	}
}
const loadingSelectors = {
	getAuthLoading: (state = loadingInitialState) => state.authLoading,
	getAppLoading: (state = loadingInitialState) => state.appLoading
}

// export root reducer
export const rootReducer = combineReducers({
	router: connectRouter(history),
	user: userReducer,
	loading: loadingReducer,
	admin: adminReducer,
	appointment: appointmentReducer,
	preInfo: preInfoReducer,
	patient: patientReducer,
	booking: bookingReducer,
	treatmentPlan: treatmentPlanReducer,
	notification: notificationReducer,
	company: companyReducer,
	treatmentNotes: treatmentNotesReducer,
	customerBookings: customerBookingReducer
})

// export history
export { history }

// export getters/ selectors
export { userSelectors, loadingSelectors, appointmentSelectors }
