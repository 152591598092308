import { treatmentNotesTypes } from './types'

export const createNewTreatmentNotes = data => {
	return {
		type: treatmentNotesTypes.createNewTreatmentNotes,
		payload: {
			request: {
				method: 'POST',
				url: '/treatment_notes',
				data
			}
		}
	}
}

export const getTreatmentNotesById = customerId => {
	return {
		type: treatmentNotesTypes.getTreatmentNotesById,
		payload: {
			request: {
				method: 'GET',
				url: `treatment_notes/customer/${customerId}`
			}
		}
	}
}

export const updateTreatmentNotes = (treatmentNotesId, data) => {
	return {
		type: treatmentNotesTypes.updateTreatmentNotes,
		payload: {
			request: {
				method: 'PATCH',
				url: `/treatment_notes/${treatmentNotesId}`,
				data
			}
		}
	}
}