/**
 *
 * @author Trung H <trung@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */
import {
	UPLOAD_USER_AVATAR,
	GET_USER_BY_ID,
	SIGN_IN,
	SIGN_UP,
	SIGN_OUT,
	TWOFA_SIGN_IN,
	RESET_PASSWORD,
	FORGOT_PASSWORD,
	UPDATE_USER,
	UPDATE_ROLE,
	UPDATE_USER_SUCCESS,
	UPDATE_USER_FAIL,
	AUTHORIZE_PERSON_SUCCESS,
	AUTHORIZE_PERSON_FAIL,
	AUTHORIZE_PERSON,
	GET_BOOKINGS,
	GET_BOOKINGS_SUCCESS,
	GET_BOOKINGS_FAIL,
	GET_CALENDAR,
	GET_CALENDAR_SUCCESS,
	GET_CALENDAR_FAIL,
	UPDATE_CALENDAR,
	UPDATE_CALENDAR_SUCCESS,
	UPDATE_CALENDAR_FAIL,
	CREATE_CALENDAR,
	CREATE_CALENDAR_FAIL,
	CREATE_CALENDAR_SUCCESS,
	GET_ALL_BOOKINGS_SUCCESS,
	GET_ALL_BOOKINGS,
	GET_ALL_BOOKINGS_FAIL,
	GET_USERS_BY_ROLE,
	GET_USERS_BY_ROLE_SUCCESS,
	GET_USERS_BY_ROLE_FAIL,
	GET_PRE_INFO,
	GET_PRE_INFO_SUCCESS,
	GET_PRE_INFO_FAIL,
	SEND_PRE_INFO,
	SEND_PRE_INFO_SUCCESS,
	SEND_PRE_INFO_FAIL,
	UPDATE_BOOKING_CALENDAR,
	UPDATE_BOOKING_CALENDAR_SUCCESS,
	UPDATE_BOOKING_CALENDAR_FAIL,
	UPDATE_PRE_INFO,
	UPDATE_PRE_INFO_SUCCESS,
	UPDATE_PRE_INFO_FAIL,
	UPDATE_USER_PASSWORD,
	UPDATE_USER_PASSWORD_SUCCESS,
	UPDATE_USER_PASSWORD_FAIL,
	GET_TREATMENT_PLANS,
	GET_TREATMENT_PLANS_SUCCESS,
	GET_TREATMENT_PLANS_FAIL,
	GET_TREATMENT_PLANS_CUSTOMER,
	GET_TREATMENT_PLANS_CUSTOMER_SUCCESS,
	GET_TREATMENT_PLANS_CUSTOMER_FAIL,
	GET_AVAILABLE_BOOKINGS,
	GET_AVAILABLE_BOOKINGS_SUCCESS,
	GET_AVAILABLE_BOOKINGS_FAIL,
	GET_ALLAVAILABLE_BOOKINGS,
	GET_ALLAVAILABLE_BOOKINGS_SUCCESS,
	GET_ALLAVAILABLE_BOOKINGS_FAIL,
	RESET_USER_ERRORS,
	GET_CIRCLE_OF_OWN_SITUATION,
	GET_CIRCLE_OF_OWN_SITUATION_SUCCESS,
	GET_CIRCLE_OF_OWN_SITUATION_FAIL,
	CREATE_CIRCLE_OF_OWN_SITUATION,
	CREATE_CIRCLE_OF_OWN_SITUATION_SUCCESS,
	CREATE_CIRCLE_OF_OWN_SITUATION_FAIL,
	CREATE_LOCATION,
	CREATE_LOCATION_SUCCESS,
	CREATE_LOCATION_FAIL,
	COPY_CALENDAR,
	COPY_CALENDAR_SUCCESS,
	COPY_CALENDAR_FAIL,
	GET_NEW_ACCESS_TOKEN,
	GET_NEW_REFRESH_TOKEN,
	GET_CONSENTED_PROVIDERS,
	GET_CONSENTED_PROVIDERS_SUCCESS,
	UPDATE_COMPANY,
	UPDATE_COMPANY_SUCCESS,
	DELETE_CALENDAR,
	DELETE_CALENDAR_SUCCESS,
	DELETE_CALENDAR_FAIL,
	twoFAtypes,
	GET_WEEK_CALENDAR,
	GET_WEEK_CALENDAR_SUCCESS,
	GET_WEEK_CALENDAR_FAIL
} from './types'
import { API } from '../constants'
import { replaceParamsUri } from '../helpers/apiHelpers'

export const updateRole = role => {
	return {
		type: UPDATE_ROLE,
		role
	}
}

export const signOut = () => {
	localStorage.clear()
	sessionStorage.clear()
	return {
		type: SIGN_OUT
	}
}

export const getUserById = userId => {
	const apiUri = replaceParamsUri(['id'], [userId], API.GET_USER_BY_ID)
	return {
		type: GET_USER_BY_ID,
		payload: {
			request: {
				method: 'GET',
				url: apiUri
			}
		}
	}
}

export const getNewAccessToken = refreshToken => {
	return {
		type: GET_NEW_ACCESS_TOKEN,
		payload: {
			request: {
				method: 'GET',
				url: `auth/token/refresh`,
				params: { refreshToken }
			}
		}
	}
}

export const getNewRefreshToken = refreshToken => {
	return {
		type: GET_NEW_REFRESH_TOKEN,
		payload: {
			request: {
				method: 'GET',
				url: `auth/token/refresh/refresh`,
				params: { refreshToken }
			}
		}
	}
}

export const signIn = data => {
	return {
		type: SIGN_IN,
		payload: {
			request: {
				method: 'POST',
				url: API.SIGN_IN,
				data
			}
		}
	}
}

export const twoFaSignIn = data => {
	return {
		type: TWOFA_SIGN_IN,
		payload: {
			request: {
				method: 'POST',
				url: API.TWOFA_SIGNIN,
				data
			}
		}
	}
}

export const setupTwoFa = email => {
	return {
		type: twoFAtypes.createTwoFA,
		payload: {
			request: {
				method: 'GET',
				url: `auth/setup-2fa`,
				params: {email}
			}
		}
	}
}

export const setusersecret = data => {
	return {
		type: twoFAtypes.addSecret,
		payload: {
			request: {
				method: 'POST',
				url: `auth/setup-2fa`,
				data: data
			}
		}
	}
}


export const userSignUp = data => {
	return {
		type: SIGN_UP,
		payload: {
			request: {
				method: 'POST',
				url: API.USER_SIGN_UP,
				data: data
			}
		}
	}
}

export const serviceProviderSignUp = data => {
	return {
		type: SIGN_UP,
		payload: {
			request: {
				method: 'POST',
				url: API.SERVICE_PROVIDER_SIGN_UP,
				data: data
			}
		}
	}
}

export const forgotPassword = data => {
	return {
		type: FORGOT_PASSWORD,
		payload: {
			request: {
				method: 'GET',
				url: API.FORGOT_PASSWORD + '?email=' + data.email
			}
		}
	}
}

export const resetPassword = (token, data) => {
	return {
		type: RESET_PASSWORD,
		payload: {
			request: {
				headers: {
					authorization: 'Bearer ' + token
				},
				method: 'POST',
				url: API.RESET_PASSWORD,
				data
			}
		}
	}
}

export const updateUser = (userId, data) => ({
	type: UPDATE_USER,
	payload: {
		request: {
			method: 'PATCH',
			url: API.UPDATE_USER + userId,
			data
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: UPDATE_USER_SUCCESS,
					payload: data
				})
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: UPDATE_USER_FAIL,
					payload: error?.response?.data?.message,
					error: error
				})
			}
		}
	}
})

export const updateUserPassword = data => ({
	type: UPDATE_USER_PASSWORD,
	payload: {
		request: {
			method: 'PATCH',
			url: API.UPDATE_USER_PASSWORD,
			data: data
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: UPDATE_USER_PASSWORD_SUCCESS,
					payload: data
				})
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: UPDATE_USER_PASSWORD_FAIL,
					payload: error?.response?.data?.message,
					error: error
				})
			}
		}
	}
})

export const authorizePerson = (method, data, consentId) => ({
	type: AUTHORIZE_PERSON,
	payload: {
		request: {
			method: method,
			url: API.AUTHORIZE_PERSON + (method === 'PATCH' ? '/' + consentId : ''),
			data: data
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: AUTHORIZE_PERSON_SUCCESS
				})
				dispatch(getConsentedProviders())
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: AUTHORIZE_PERSON_FAIL,
					payload: error?.response?.data?.message,
					error: error
				})
			}
		}
	}
})

// export const authorizePerson = (data) => ({
// 	type: AUTHORIZE_PERSON,
// 	payload: {
// 		request: {
// 			method: 'PATCH',
// 			url: API.UPDATE_USER + userId,
// 			data
// 		},
// 		options: {
// 			async onSuccess({ getState, dispatch, response }) {
// 				setTimeout(() => {dispatch({
// 					type: AUTHORIZE_PERSON_SUCCESS,
// 					payload: data
// 				})}, 1000)

// 			},
// 			onError({ getState, dispatch, response, error }) {
// 				dispatch({
// 					type: AUTHORIZE_PERSON_FAIL,
// 					payload: error?.response?.data?.message
// 				})
// 			}
// 		}
// 	}
// })

export const getBookings = () => ({
	type: GET_BOOKINGS,
	payload: {
		request: {
			method: 'GET',
			url: API.GET_BOOKINGS
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: GET_BOOKINGS_SUCCESS,
					payload: response.data
				})
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: GET_BOOKINGS_FAIL,
					payload: error?.response?.data?.message,
					error: error
				})
			}
		}
	}
})

export const getAllBookings = () => ({
	type: GET_ALL_BOOKINGS,
	payload: {
		request: {
			method: 'GET',
			url: API.GET_ALL_BOOKINGS
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: GET_ALL_BOOKINGS_SUCCESS,
					payload: response.data
				})
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: GET_ALL_BOOKINGS_FAIL,
					payload:
						!!error.response.data && !!error.response.data.message
							? error.response.data.message
							: 'There was an error while requesting booking data.',
					error: error
				})
			}
		}
	}
})

export const updateBookingCalendar = (bookingId, calendarId) => ({
	type: UPDATE_BOOKING_CALENDAR,
	payload: {
		request: {
			method: 'PATCH',
			url: `bookings/${bookingId}/update`,
			data: {bookingId, calendarId}
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: UPDATE_BOOKING_CALENDAR_SUCCESS,
					payload: response.data
				})
				// dispatch(getCalendar())
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: UPDATE_BOOKING_CALENDAR_FAIL,
					payload: error?.response?.data?.message,
					error: error
				})
			}
		}
	}
})

export const getTreatmentPlans = () => ({
	type: GET_TREATMENT_PLANS,
	payload: {
		request: {
			method: 'GET',
			url: API.GET_TREATMENT_PLANS
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: GET_TREATMENT_PLANS_SUCCESS,
					payload: response.data
				})
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: GET_TREATMENT_PLANS_FAIL,
					payload: !!error.response.data
						? error.response.data.message
						: 'There was an error while handling your request',
					error: error
				})
			}
		}
	}
})

export const getTreatmentPlansCustomer = customerId => ({
	type: GET_TREATMENT_PLANS_CUSTOMER,
	payload: {
		request: {
			method: 'GET',
			url: replaceParamsUri(
				['customerId'],
				[customerId],
				API.GET_TREATMENT_PLANS_CUSTOMER
			)
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: GET_TREATMENT_PLANS_CUSTOMER_SUCCESS,
					payload: response.data
				})
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: GET_TREATMENT_PLANS_CUSTOMER_FAIL,
					payload: !!error.response.data
						? error.response.data.message
						: 'There was an error while handling your request',
					error: error
				})
			}
		}
	}
})

export const getCalendar = () => ({
	type: GET_CALENDAR,
	payload: {
		request: {
			method: 'GET',
			url: API.GET_CALENDAR
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: GET_CALENDAR_SUCCESS,
					payload: response.data
				})
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: GET_CALENDAR_FAIL,
					payload: error?.response?.data?.message,
					error: error
				})
			}
		}
	}
})

export const getWeekCalendar = data => ({
	type: GET_WEEK_CALENDAR,
	payload: {
		request: {
			method: 'GET',
			url: replaceParamsUri(['startDay'], [data], API.GET_WEEK_CALENDAR)
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: GET_WEEK_CALENDAR_SUCCESS,
					payload: response.data
				})
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: GET_WEEK_CALENDAR_FAIL,
					payload: error?.response?.data?.message,
					error: error
				})
			}
		}
	}
})

export const createCalendar = (calendarId, startTime, endTime, calendarStart) => ({
	type: CREATE_CALENDAR,
	payload: {
		request: {
			method: 'POST',
			url: '/calendar',
			data: {
				startTime: startTime,
				endTime: endTime,
				location: calendarId,
				status: 'AVAILABLE'
			}
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: CREATE_CALENDAR_SUCCESS,
					payload: response.data
				})
				dispatch(getWeekCalendar(calendarStart))
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: CREATE_CALENDAR_FAIL,
					payload: error?.response?.data?.message,
					error: error
				})
			}
		}
	}
})

export const updateCalendar = (calendarId, startTime, endTime, calendarStart) => ({
	type: UPDATE_CALENDAR,
	payload: {
		request: {
			method: 'PATCH',
			url: replaceParamsUri(['calendarId'], [calendarId], API.UPDATE_CALENDAR),
			data: {
				startTime: startTime,
				endTime: endTime,
				status: 'AVAILABLE'
			}
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: UPDATE_CALENDAR_SUCCESS,
					payload: response.data
				})
				dispatch(getWeekCalendar(calendarStart))
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: UPDATE_CALENDAR_FAIL,
					payload: error?.response?.data?.message,
					error: error
				})
			}
		}
	}
})

export const copyCalendar = (locationId, calendarStart) => ({
	type: COPY_CALENDAR,
	payload: {
		request: {
			method: 'POST',
			url: replaceParamsUri(['locationId'], [locationId], API.COPY_CALENDAR)
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: COPY_CALENDAR_SUCCESS,
					payload: response.data
				})
				dispatch(getWeekCalendar(calendarStart))
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: COPY_CALENDAR_FAIL,
					payload: error?.response?.data?.message,
					error: error
				})
			}
		}
	}
})

export const deleteCalendar = (calendarId) => ({
	type: DELETE_CALENDAR,
	payload: {
		request: {
			method: 'DELETE',
			url: `calendar/${calendarId}/remove`
		},
		options: {
			async onSuccess({dispatch, response}) {
				dispatch({
					type: DELETE_CALENDAR_SUCCESS,
					payload: response.data
				})
			},
			onError({dispatch, error}) {
				dispatch({
					type: DELETE_CALENDAR_FAIL,
					payload: error?.response?.data?.message,
					error: error
				})
			}
		}
	}	
})

export const getUsersByRole = role => ({
	type: GET_USERS_BY_ROLE,
	payload: {
		request: {
			method: 'GET',
			url: replaceParamsUri(['role'], [role], API.GET_USERS_BY_ROLE)
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: GET_USERS_BY_ROLE_SUCCESS,
					payload: response.data,
					role: role
				})
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: GET_USERS_BY_ROLE_FAIL,
					payload: error?.response?.data?.message,
					error: error
				})
			}
		}
	}
})

export const getPreInfo = customerId => ({
	type: GET_PRE_INFO,
	payload: {
		request: {
			method: 'GET',
			url: replaceParamsUri(['customerId'], [customerId], API.GET_PRE_INFO)
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: GET_PRE_INFO_SUCCESS,
					payload: response.data
				})
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: GET_PRE_INFO_FAIL,
					payload: error?.response?.data?.message,
					error: error
				})
			}
		}
	}
})

export const sendPreInfo = (preInfoType, body) => ({
	type: SEND_PRE_INFO,
	payload: {
		request: {
			method: 'POST',
			url: replaceParamsUri(
				['preInfoType'],
				[preInfoType.includes('massage') ? 'massage' : preInfoType],
				API.SEND_PRE_INFO
			),
			data: body
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: SEND_PRE_INFO_SUCCESS,
					payload: response.data
				})
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: SEND_PRE_INFO_FAIL,
					payload: error?.response?.data?.message,
					error: error
				})
			}
		}
	}
})

export const updatePreInfo = (preInfoId, body) => ({
	type: UPDATE_PRE_INFO,
	payload: {
		request: {
			method: 'PATCH',
			url: replaceParamsUri(['preInfoId'], [preInfoId], API.UPDATE_PRE_INFO),
			data: body
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: UPDATE_PRE_INFO_SUCCESS,
					payload: response.data
				})
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: UPDATE_PRE_INFO_FAIL,
					payload: error?.response?.data?.message,
					error: error
				})
			}
		}
	}
})

export const getConsentedProviders = () => {
	return {
		type: GET_CONSENTED_PROVIDERS,
		payload: {
			request: {
				method: 'GET',
				url: API.GET_CONSENTED_PROVIDERS
			}
		}
	}
}

export const getAvailableBookings = (service_type, date) => ({
	type: GET_AVAILABLE_BOOKINGS,
	payload: {
		request: {
			method: 'GET',
			url: replaceParamsUri(
				['service_type', 'date'],
				[service_type, date],
				API.AVAILABLE_BOOKING_TIMES
			)
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: GET_AVAILABLE_BOOKINGS_SUCCESS,
					payload: response.data
				})
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: GET_AVAILABLE_BOOKINGS_FAIL,
					payload: error?.response?.data?.message,
					error: error
				})
			}
		}
	}
})

export const getAllAvailableBookings = (service_type, date) => ({
	type: GET_ALLAVAILABLE_BOOKINGS,
	payload: {
		request: {
			method: 'GET',
			url: replaceParamsUri(
				['service_type', 'date'],
				[service_type, date],
				API.AVAILABLE_BOOKING_TIMES
			)
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: GET_ALLAVAILABLE_BOOKINGS_SUCCESS,
					payload: response.data
				})
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: GET_ALLAVAILABLE_BOOKINGS_FAIL,
					payload: error?.response?.data?.message,
					error: error
				})
			}
		}
	}
})

export const createCircleOfOwnSituation = data => ({
	type: CREATE_CIRCLE_OF_OWN_SITUATION,
	payload: {
		request: {
			method: 'POST',
			url: API.CREATE_CIRCLE_OF_OWN_SITUATION,
			data: data
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: CREATE_CIRCLE_OF_OWN_SITUATION_SUCCESS,
					payload: response.data
				})
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: CREATE_CIRCLE_OF_OWN_SITUATION_FAIL,
					payload: error?.response?.data?.message,
					error: error
				})
			}
		}
	}
})

export const getCircleOfOwnSituation = customerId => ({
	type: GET_CIRCLE_OF_OWN_SITUATION,
	payload: {
		request: {
			method: 'GET',
			url: replaceParamsUri(
				['customerId'],
				[customerId],
				API.GET_CIRCLE_OF_OWN_SITUATION
			)
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: GET_CIRCLE_OF_OWN_SITUATION_SUCCESS,
					payload: response.data
				})
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: GET_CIRCLE_OF_OWN_SITUATION_FAIL,
					payload: error?.response?.data?.message,
					error: error
				})
			}
		}
	}
})

export const createLocation = data => ({
	type: CREATE_LOCATION,
	payload: {
		request: {
			method: 'POST',
			url: API.CREATE_LOCATION,
			data: data
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: CREATE_LOCATION_SUCCESS,
					payload: response.data
				})
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: CREATE_LOCATION_FAIL,
					payload: error?.response?.data?.message,
					error: error
				})
			}
		}
	}
})

export const resetUserErrors = () => {
	return {
		type: RESET_USER_ERRORS
	}
}

export const uploadUserAvatar = data => {
	return {
		type: UPLOAD_USER_AVATAR,
		payload: {
			request: {
				method: 'POST',
				url: API.UPLOAD_USER_AVATAR,
				data
			}
		}
	}
}

export const updateCompany = (data, companyId, userId) => ({
	type: UPDATE_COMPANY,
	payload: {
		request: {
			method: 'PATCH',
			url: replaceParamsUri(['companyId'], [companyId], API.UPDATE_COMPANY),
			data
		},
		options: {
			async onSuccess({ getState, dispatch, response }) {
				dispatch({
					type: UPDATE_COMPANY_SUCCESS,
					payload: response.data
				})
				dispatch(getUserById(userId))
			},
			onError({ getState, dispatch, response, error }) {
				dispatch({
					type: UPDATE_COMPANY_FAIL,
					payload: error?.response?.data?.message,
					error: error
				})
			}
		}
	}
})
