import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
// reducer
// actions
import { notificationActions } from 'actions'
import { useTranslation } from 'react-i18next'
export default function SimpleSnackbar() {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [open, setOpen] = useState(false)
	const notification = useSelector(state => state.notification.notification)
	useEffect(() => {
		if (notification) {
			setOpen(true)
		}
	}, [notification])

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}
		dispatch(notificationActions.resetNotification())
		setOpen(false)
	}
	if (!notification) {
		return null
	}
	const { message, duration, type } = notification
	return (
		<div className='snackbar'>
			<Snackbar
				open={open}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				autoHideDuration={duration}
				onClose={handleClose}
			>
				<div>
					<Alert onClose={handleClose} severity={type}>
						{t(message)}
					</Alert>
				</div>
			</Snackbar>
		</div>
	)
}
function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />
}
