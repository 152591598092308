/**
 *
 * @author Jere Rantalainen <jere@vertics.co>
 *
 * @copyright Vertics Oy 2021
 */
import { GET_PROVIDERS_ADMIN_SUCCESS } from '../actions/types'

const initialState = {
	serviceProviders: []
}

export const adminReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_PROVIDERS_ADMIN_SUCCESS: {
			return {
				...state,
				serviceProviders: action.payload.data.filter(
					item => item.role === 'SERVICE_PROVIDER'
				)
			}
		}

		default:
			return state
	}
}

export const adminSelectors = {
	getServiceProviders: (state = initialState) => state.serviceProviders
}
