import { API } from '../constants'
import { replaceParamsUri } from '../helpers/apiHelpers'
import {
	ACTIVATE_PROVIDER,
	DEACTIVATE_PROVIDER,
	DELETE_SECRET,
	GET_PROVIDERS_ADMIN,
	VERIFY_PROVIDER
} from './types'

export const getProvidersAdmin = () => {
	return {
		type: GET_PROVIDERS_ADMIN,
		payload: {
			request: {
				method: 'GET',
				url: API.GET_PROVIDERS_ADMIN
			}
		}
	}
}

export const activateProvider = id => {
	return {
		type: ACTIVATE_PROVIDER,
		payload: {
			request: {
				method: 'PATCH',
				url: replaceParamsUri(['id'], [id], API.ACTIVATE_PROVIDER)
			}
		}
	}
}

export const deactivateProvider = id => {
	return {
		type: DEACTIVATE_PROVIDER,
		payload: {
			request: {
				method: 'PATCH',
				url: replaceParamsUri(['id'], [id], API.DEACTIVATE_PROVIDER)
			}
		}
	}
}

export const verifyProvider = id => {
	return {
		type: VERIFY_PROVIDER,
		payload: {
			request: {
				method: 'PATCH',
				url: replaceParamsUri(['id'], [id], API.VERIFY_PROVIDER)
			}
		}
	}
}

export const deleteProviderSecret = id => {
	return {
		type: DELETE_SECRET,
		payload: {
			request: {
				method: 'PATCH',
				url: replaceParamsUri(['id'], [id], API.DELETE_SECRET)
			}
		}
	}
}
