import { preInfoTypes } from './types'

export const getPreInfoById = id => {
	return {
		type: preInfoTypes.getPreInfoById,
		payload: {
			request: {
				method: 'GET',
				url: `/pre_info/customer/${id}`
			}
		}
	}
}
