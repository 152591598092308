/**
 *
 * @author Trung H <trung@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */
import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// Actions
import { userActions } from '../../../actions'

// Assets
import arrowDown from '../../../assets/icons/arrowDown.svg'

import { ROUTER_PATH, USER_ROLE } from '../../../constants'
import logoUri from '../../../assets/Akupunktioklinikka_logo.png'
import i18n from '../../../services/i18n'
import { useHistory } from 'react-router-dom'
const options = [
	{
		value: 'Project 1'
	},
	{
		value: 'Project 2'
	}
]
const languages = ['EN', 'FI']
const Nav = props => {
	const { logout, navStatus = false, onClick } = props
	const history = useHistory()
	const user = useSelector(state => state.user)
	const [currentPath, setCurrentPath] = useState(history.location.pathname)
	useEffect(() => {
		setCurrentPath(history.location.pathname)
	}, [history.location.pathname])
	const [selectedProject, setSelectedProject] = useState(options[0].value)
	const [selectedLanguage, setSelectedLanguage] = useState(languages[0])
	const [showProjectOptions, setShowProjectOptions] = useState(false)
	const { t } = useTranslation()
	const selectProject = e => {
		const projectName = e.currentTarget.getAttribute('projectname')
		setSelectedProject(projectName)
	}
	const selectLanguage = e => {
		const language = e.currentTarget.getAttribute('language')
		i18n.changeLanguage(language, () => {
			// eslint-disable-next-line no-console
			console.log('Language is changed to', language)
		})
		setSelectedLanguage(language)
	}
	const toggleOnHover = () => setShowProjectOptions(!showProjectOptions)
	const navigateTo = path => {
		let navigationLocation = path

		if (!!!navigationLocation) {
			navigationLocation = '/'
		}
		if (
			window.location.href ===
			window.location.origin + (!!navigationLocation ? navigationLocation : '/')
		) {
			/* Navigation to the same page prevented */
		} else {
			history.push(navigationLocation)
		}
	}
	const signOut = () => {
		logout()
		// localStorage.removeItem('refreshToken')
		sessionStorage.removeItem('refreshToken')
		history.push(ROUTER_PATH.LOGIN)
	}
	const renderNavItem = ({ path, iconClassName, name, popupItems }) => (
		<div
			className={`nav__item-container ${
				currentPath === path || popupItems.includes(currentPath.substring(1))
					? 'is-active'
					: ''
			}`}
			key={name}
		>
			<button
				onClick={() => {
					if (popupItems.length > 0) {
					} else {
						navigateTo(path)
						onClick()
					}
				}}
				className='nav__item btn btn--transparent'
				to={path}
			>
				<i className={`nav__item-icon fal ${iconClassName}`} />
				{name}
			</button>
			{popupItems.length > 0 ? <>{renderNavPopupItem(popupItems)}</> : null}
		</div>
	)

	const renderNavPopupItem = popupItems => (
		<div className='nav__item-popup'>
			<ul>
				{popupItems.map((item, index) => (
					<li key={'popupitem' + index} className='nav__item-popup-item'>
						<button
							onClick={() => {
								history.push('/' + item)
								onClick()
							}}
							className='btn btn--transparent'
						>
							{t('navigationbar.' + item)}
						</button>
					</li>
				))}
			</ul>
		</div>
	)
	const navigationItemsCustomer = [
		{
			path: ROUTER_PATH.HOME,
			iconClassName: 'fa-home',
			name: t('navigationbar.home'),
			popupItems: []
		},
		{
			path: ROUTER_PATH.PROFILE,
			iconClassName: 'fa-user',
			name: t('navigationbar.profile'),
			popupItems: ['profile', 'own-status', 'authorized-personnel']
		},
		{
			path: ROUTER_PATH.APPOINTMENT,
			iconClassName: 'fa-calendar',
			name: t('navigationbar.booking'),
			popupItems: ['appointment', 'appointment/new']
		},
		{
			path: ROUTER_PATH.TREATMENT_PLAN,
			iconClassName: 'fa-sticky-note',
			name: t('navigationbar.treatment_plan'),
			popupItems: []
		}
	]
	const navigationItemsServiceProvider = [
		{
			path: ROUTER_PATH.HOME,
			iconClassName: 'fa-home',
			name: t('navigationbar.home'),
			popupItems: []
		},
		{
			path: ROUTER_PATH.PROFILE,
			iconClassName: 'fa-user',
			name: t('navigationbar.profile'),
			popupItems: []
		},
		{
			path: ROUTER_PATH.CALENDAR,
			iconClassName: 'fa-calendar',
			name: t('navigationbar.calendar'),
			popupItems: ['calendar', 'calendar/add-service', 'calendar/booking']
		},
		{
			path: ROUTER_PATH.PATIENTS,
			iconClassName: 'fa-sticky-note',
			name: t('navigationbar.patients'),
			popupItems: []
		}
	]
	return (
		<nav className={navStatus ? '' : 'nav--closed'}>
			<div className='nav__logo'>
				<img className='nav__logo-image' src={logoUri} />
				<div className='nav__logo-image-description'>Akupunktioklinikka</div>
			</div>
			<div className='nav'>
				<button
					className='btn btn--transparent nav__close-button'
					onClick={() => onClick()}
				>
					{<img className='nav__close-button-icon' src={arrowDown} alt='^' />}
				</button>
				{user.role === USER_ROLE.USER
					? navigationItemsCustomer.map(renderNavItem)
					: user.role === USER_ROLE.SERVICE_PROVIDER
					? navigationItemsServiceProvider.map(renderNavItem)
					: null}
			</div>
			<div className='nav__item-container-signout' onClick={logout}>
				<div className='nav__item'>
					{t('navigationbar.logout')}
					<i className={`nav__item-icon fal fa-sign-out`} />
				</div>
			</div>
		</nav>
	)
}

export default connect(
	state => ({}),
	dispatch => ({
		logout: () => dispatch(userActions.signOut())
	})
)(Nav)
