import { bookingTypes } from './types'

export const getAllBookingById = (customerId, data) => {
	return {
		type: bookingTypes.getAllBookingById,
		payload: {
			request: {
				method: 'GET',
				url: `bookings/customer/${customerId}`,
				data: {data}
			}
		}
	}
}
export const getAllBookingBycustomerId = (customerId) => {
	return {
		type: bookingTypes.getAllBookingByCustomerId,
		payload: {
			request: {
				method: 'GET',
				url: `bookings/customer/${customerId}/notes`
			}
		}
	}
}
export const deleteBooking = bookingId => {
	return {
		type: bookingTypes.deleteBooking,
		payload: {
			request: {
				method: 'DELETE',
				url: `bookings/${bookingId}/cancel`
			}
		}
	}
}
