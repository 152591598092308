/**
 * Main container
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */

// WORK INSTRUCTIONS:
// - Please do not remove the above header. It protects the IPR intrests of our company.
// - Please replace the author name with your own full real name.

import { hot } from 'react-hot-loader/root'
import React, { useEffect, useState, Suspense, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'

// Components
import CoreLayout from '../shared/components/layout/CoreLayout'
import ErrorMessage from './UI/ErrorMessage'
import LoadingCircle from './UI/LoadingCircle'
import { loadingSelectors } from '../reducers'
import { primaryColor } from '../constants'
import Loading from '../shared/components/Loading'

import { userActions, notificationActions } from 'actions'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

const LoadingScreen = () => (
	<div className='loading-screen'>
		<LoadingCircle color={primaryColor} />
	</div>
)

const App = ({ authLoading, appLoading, dashboardLoading }) => {
	const [loading, setLoading] = useState(false)
	const history = useHistory()
	const dispatch = useDispatch()

	useEffect(() => {
		if (authLoading || appLoading || dashboardLoading) {
			setLoading(true)
			// set maximum loading = 10s, prevent infinitie loading
			setTimeout(() => {
				if (loading) {
					setLoading(false)
				}
			}, 10000)
		} else {
			setLoading(false)
		}
	}, [authLoading, appLoading, dashboardLoading])

	useEffect(() => {
		if (sessionStorage.getItem('idleTime') !== null) {
			// let check = 0
			const timer = setInterval(() => {
				// check += 1
				if (sessionStorage.getItem('idleTime') === null) {
					clearInterval(timer)
					// console.log('timer cleared')
				} else {
					// let remaining = sessionStorage.getItem('idleTime') - Number(new Date())
					// console.log(check + ' : Timer: ' + remaining)
					if (sessionStorage.getItem('idleTime') < Number(new Date())) {
						// console.log('logging out...')
						dispatch(userActions.signOut())
						clearInterval(timer)
						dispatch(
							notificationActions.showNotification({
								type: 'error',
								message: 'Logging out due inactivity',
								duration: null
							})
						)
					}
				}
			}, 5000)
		}
	}, [sessionStorage.getItem('idleTime')])

	let [idleTime] = useState(true)
	let timeout = null

	const handleIdleTime = useCallback(event => {
		if (timeout) {
			clearTimeout(timeout)
		}
		timeout = setTimeout(() => {
			sessionStorage.setItem(
				'idleTime',
				Number(moment(new Date()).add(process.env.IDLE_TIME, 'minutes'))
			)
		}, 5000)
	}, [])

	useEffect(() => {
		if (sessionStorage.getItem('idleTime') !== null) {
			if (sessionStorage.getItem('idleTime') === null) {
				idleTime = false
				window.removeEventListener('mousemove', handleIdleTime)
			}
			if (idleTime) {
				window.addEventListener('mousemove', handleIdleTime)
			} else {
				window.removeEventListener('mousemove', handleIdleTime)
			}
			return () => window.removeEventListener('mousemove', handleIdleTime)
		}

		if (sessionStorage.getItem('idleTime') === null) {
			// console.log("stuff?")
			window.removeEventListener('mousemove', handleIdleTime)
			return () => window.removeEventListener('mousemove', handleIdleTime)
		}
	}, [idleTime, handleIdleTime, sessionStorage.getItem('idleTime')])

	return (
		<Suspense
			fallback={
				<div className='loading__page-container'>
					<div className='loading__page-container-bg'></div>
					<Loading />
				</div>
			}
		>
			<Helmet
				titleTemplate='Akupunktioklinikka | %s'
				defaultTitle='Akupunktioklinikka'
			>
				<meta name='description' content='Akupunktioklinikka application' />
				<link rel='icon' href='../assets/Akupunktioklinikka_logo.png' />
			</Helmet>
			<CoreLayout />
			<ErrorMessage />
			{/* {loading ? <LoadingScreen /> : null} */}
		</Suspense>
	)
}

export default hot(
	connect(state => ({
		authLoading: loadingSelectors.getAuthLoading(state.loading),
		appLoading: loadingSelectors.getAppLoading(state.loading)
	}))(App)
)
