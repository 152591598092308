import { companyTypes as types } from 'actions/types'

const initialState = {
	company: [],

	error: null,
	loading: false
}

export const companyReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.getServicesByCompany:
			return {
				...state,
				loading: true
			}
		case types.getServicesByCompanySuccess:
			return {
				...state,
				company: action.payload.data,
				loading: false
			}

		default:
			return state
	}
}
