import { GET_PATIENT_COOS_SUCCESS, patientTypes } from '../actions/types'

const initialState = {
	patient: {
		address: '',
		city: '',
		company: '',
		dateOfBirth: '',
		email: '',
		firstName: '',
		gender: '',
		lastName: '',
		phoneNumber: '',
		postalCode: '',
		role: ''
	},
	coos: null,
	error: null,
	loading: false
}

export const patientReducer = (state = initialState, action) => {
	switch (action.type) {
		case patientTypes.initPatientInfo:
			return { ...state, patient: action.patient }
		case GET_PATIENT_COOS_SUCCESS:
			return {
				...state,
				coos: action.payload.sort((a, b) =>
					new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1
				)
			}

		default:
			return state
	}
}
