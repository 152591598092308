/**
 * Define some frequent-use constants
 * for easier import inside components
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { UPLOAD_USER_AVATAR } from './actions/types'

// Languages
export const languages = {
	en: 'en',
	fi: 'fi'
}

// To switch between LOCAL & STAGING backend
export const IMAGE_HOST_LINK =
	'https://ajanvaraus-images.s3.eu-north-1.amazonaws.com/'
export const backendUri = process.env.BACKEND_URL || 'http://localhost:3000'

// Path to images directory
export const IMAGE_PATH = `${process.cwd()}src/assets/images`
// Path to icons directory
export const ICON_PATH = `${process.cwd()}src/assets/icons`
// Key redux persist
export const PERSIST_KEY = 'root'

// Color code
export const primaryColor = '#fc4f00'

export const API = {
	ACTIVATE_PROVIDER: '/admin/accounts/{id}/activate',
	AUTHORIZE_PERSON: '/consents',
	AVAILABLE_BOOKING_TIMES: '/calendar/service_type/{service_type}/date/{date}',
	COPY_CALENDAR: '/calendar/copy_calendar/location/{locationId}',
	CREATE_BOOKING: '/bookings',
	CREATE_CIRCLE_OF_OWN_SITUATION: '/coos',
	CREATE_LOCATION: '/locations',
	CREATE_SERVICE: '/companies/{companyId}/services',
	DEACTIVATE_PROVIDER: '/admin/accounts/{id}/deactivate',
	// DELETE_CALENDARID: '/calendar/{calendarId}/remove',
	EDIT_BOOKING: '/bookings/{bookingId}',
	EDIT_SERVICE: '/companies/{companyId}/services/{serviceId}',
	FORGOT_PASSWORD: '/auth/password/forgot',
	GET_BOOKINGS: '/bookings/my',
	GET_ALL_BOOKINGS: '/bookings',
	GET_CIRCLE_OF_OWN_SITUATION: '/coos/customer/{customerId}',
	GET_CALENDAR: '/calendar/my',
	GET_WEEK_CALENDAR: '/calendar/my/{startDay}',
	GET_COMPANIES: '/companies',
	GET_COMPANY_SERVICES: '/companies/{companyId}/services/',
	GET_CONSENTED_PROVIDERS: '/consents/service_providers',
	GET_PRE_INFO: '/pre_info/customer/{customerId}',
	GET_PROVIDERS_ADMIN: '/admin/accounts',
	GET_TREATMENT_NOTES: '/treatment_notes',
	GET_TREATMENT_PLANS: '/treatment_plans',
	GET_TREATMENT_PLANS_CUSTOMER: '/treatment_plans/customer/{customerId}',
	GET_USER_BY_ID: '/users/{id}',
	GET_USERS_BY_ROLE: '/users/role/{role}',
	REMOVE_BOOKING: '/bookings/{bookingId}/cancel',
	RESET_PASSWORD: '/auth/password/change',
	SIGN_IN: '/auth/signin',
	SIGN_OUT: '/auth/signout',
	SETUP_TWO_FA: '/setup-2fa',
	TWOFA_SIGNIN: '/auth/twofasignin',
	USER_SIGN_UP: '/auth/signup/user',
	SEND_PRE_INFO: '/pre_info/{preInfoType}',
	SERVICE_PROVIDER_SIGN_UP: '/auth/signup/service-provider',
	UPDATE_CALENDAR: '/calendar/{calendarId}',
	UPDATE_BOOKING_CALENDAR: '/booking/{bookingId}/updates',
	UPDATE_COMPANY: '/companies/{companyId}',
	UPDATE_PRE_INFO: '/pre_info/{preInfoId}',
	UPDATE_USER: '/users/',
	UPDATE_USER_PASSWORD: '/users/my/password',
	UPLOAD_USER_AVATAR: '/files',
	VERIFY_PROVIDER: '/admin/service_providers/{id}/approve',
	DELETE_SECRET: '/admin/accounts/{id}/removesecret'
}

export const ROUTER_PATH = {
	CALENDAR: '/calendar',
	CALENDAR_ADD_SERVICE: '/calendar/add-service',
	CALENDAR_BOOKING: '/calendar/booking',
	LOGIN: '/signin',
	SIGNUP: '/signup/:type',
	FORGET_PASSWORD: '/forget-password',
	RESET_PASSWORD: '/reset-password',
	HOME: '/',
	PROFILE: '/profile',
	OWN_STATUS: '/own-status',
	AUTHORIZED_PERSONNEL: '/authorized-personnel',
	PRE_INFO_REMINDER: '/pre-info-reminder/:type/:personType',
	PRE_INFO_FORM: '/pre-info-form/:type/:personType',
	BOOKING: '/booking',
	TREATMENT_NOTES: '/treatment-notes',
	TREATMENT_PLAN: '/treatment-plan',
	NOTIFICATION: '/notification',
	NOTIFICATION_NEW: '/notification/new',
	APPOINTMENT: '/appointment',
	APPOINTMENT_NEW: '/appointment/new',
	APPOINTMENT_BOOKING: '/appointment-booking',
	NO_SERVICE: '/no-service',
	PATIENTS: '/patients',
	PATIENT_DETAILS: '/patient/details/:id',
	PATIENT_DETAIL: '/:id/',
	PATIENT_DETAILS_PREINFO_FORM: '/:id/:type/:personType',
	TREATMENT_NOTES_NEW: '/treatmentNotes/new/:customerId/:bookingId',
	TREATMENT_NOTES_UPDATE: '/treatmentNotes/update/:customerId/:noteId',
	TREATMENT_NOTES_DETAILS: '/treatmentNotes/details/:customerId',
	TREATMENT_PLAN_NEW: '/treatmentPlan/new/:id',
	TREATMENT_PLAN_DETAILS: '/treatmentPlan/details/:type',
	BAZI_CALENDAR: '/bazi-calendar',
	TWOFA: '/setup-2fa',
	FORGOT2FA: '/ForgetTwoFa'
}

export const USER_ROLE = {
	USER: 'USER',
	SERVICE_PROVIDER: 'SERVICE_PROVIDER',
	ADMIN: 'ADMIN'
}

export const APPOINTMENT_TYPE = {
	UPCOMING_APPOINTMENT: 'upcomingBookings',
	PAST_APPOINTMENT: 'oldBookings'
}

export const BOOKING_PERSON_TYPE = {
	ADULT: 'adult',
	CHILD: 'child',
	PET: 'pet'
}

export const CALENDAR_VIEW_TYPE = {
	WEEK: 'week',
	MONTH: 'month',
	LIST: 'list'
}

export const WEEKDAYS = [
	'Sunnuntai',
	'Maanantai',
	'Tiistai',
	'Keskiviikko',
	'Torstai',
	'Perjantai',
	'Lauantai'
]

export const AUTHORIZED_PERSONNEL_SERVICES = [
	'acupuncture',
	'massage',
	'homeopathy',
	'cosmetic_Gua_Sha',
	'shiatsu',
	'sound_Bowl_Massage'
]

export const SERVICES_SELECTION = [
	{ value: 'ACUPUNCTURE', label: 'acupuncture' },
	{ value: 'MASSAGE', label: 'massage' },
	{ value: 'HOMEOPATHY', label: 'homeopathy' },
	{ value: 'SHIATSU', label: 'shiatsu' },
	{ value: 'COSMETIC_GUA_SHA', label: 'cosmetic GuaSha' },
	{ value: 'SOUND_BOWL_MASSAGE', label: 'sound bowl massage' }
]
export const PERSON_TYPES = ['adult', 'child', 'pet']

export const PERSON_TYPES_SELECTION = [
	{ value: 'ADULT', label: 'Adult' },
	{ value: 'CHILD', label: 'Child' },
	{ value: 'PET', label: 'Pet' }
]

export const SIGN_UP_CUSTOMER_INPUT_ITEMS = [
	{
		case: 'Input',
		label: 'Etunimi',
		name: 'firstName',
		type: 'text',
		icon: ''
	},
	{
		case: 'Input',
		label: 'Sukunimi',
		name: 'lastName',
		type: 'text',
		icon: ''
	},
	{
		case: 'Input',
		label: 'Sähköposti',
		name: 'email',
		type: 'email',
		icon: ''
	},
	{
		case: 'PhoneNumber',
		label: 'Puhelinnumero',
		name: 'phoneNumber',
		type: 'tel',
		icon: ''
	},
	{
		case: 'PasswordInput',
		label: 'headers.password',
		name: 'password',
		type: 'password',
		icon: ''
	},
	{
		case: 'PasswordInput',
		label: 'headers.confirmPassword',
		name: 'confirmPassword',
		type: 'password',
		icon: ''
	}
]

export const SIGN_UP_PROVIDER_INPUT_ITEMS = [
	{
		case: 'Input',
		label: 'Etunimi',
		name: 'firstName',
		type: 'text',
		icon: ''
	},
	{
		case: 'Input',
		label: 'Sukunimi',
		name: 'lastName',
		type: 'text',
		icon: ''
	},
	{
		case: 'Input',
		label: 'Sähköposti',
		name: 'email',
		type: 'email',
		icon: ''
	},
	{
		case: 'PhoneNumber',
		label: 'Puhelinnumero',
		name: 'phoneNumber',
		type: 'tel',
		icon: ''
	},
	{
		case: 'Input',
		label: 'Yrityksen nimi',
		name: 'companyName',
		type: 'text',
		icon: ''
	},
	{
		case: 'Input',
		label: 'Y-tunnus',
		name: 'businessId',
		type: 'text',
		icon: ''
	}
]

export const CUSTOMER_PROFILE_INPUT_ITEMS = [
	{
		case: 'Date',
		label: '',
		name: 'dateOfBirth',
		type: 'text',
		icon: 'date'
	},
	{
		case: 'Input',
		label: '',
		name: 'email',
		type: 'email',
		icon: 'email'
	},
	{
		case: 'PhoneNumber',
		label: '',
		name: 'phoneNumber',
		type: 'tel',
		icon: 'phoneNumber'
	},
	{
		case: 'PasswordInput',
		label: 'headers.password',
		name: 'password',
		type: 'password',
		icon: ''
	},
	{
		case: 'PasswordInput',
		label: 'headers.confirmPassword',
		name: 'confirmPassword',
		type: 'password',
		icon: ''
	}
]

export const RESET_PASSWORD_INPUT_ITEMS = [
	{
		case: 'PasswordInput',
		label: 'headers.password',
		name: 'password',
		type: 'password',
		icon: ''
	},
	{
		case: 'PasswordInput',
		label: 'headers.confirmPassword',
		name: 'confirmPassword',
		type: 'password',
		icon: ''
	}
]

export const PRE_INFO_SYMPTOMS = {
	ADULT: [
		'allergy',
		'migrane',
		'asthma',
		'std',
		'diabetes',
		'dizzyness',
		'fibromyalgy',
		'skinDisease',
		'thyroid',
		'epilepsy',
		'toothAche',
		'joint',
		'anemia',
		'paralysis',
		'stomachDisease',
		'backProblem',
		'mentalIllness',
		'bloodPressure',
		'msDisease',
		'tumor',
		'eyeDisease',
		'heartDisease',
		'liverDisease',
		'developmentalDisorder',
		'earDisease',
		'otherBloodCirculationDisease',
		'kidneyDisease',
		'other'
	],
	CHILD: [
		'colic',
		'allergy',
		'infestation',
		'whoopingCough',
		'influenza',
		'asthma',
		'enteroPox',
		'mouthEyeInfection',
		'diarrhoea',
		'growingPains',
		'measles',
		'liverDisease',
		'urineInfection',
		'throathInfection',
		'chickenPox',
		'meningitis',
		'otitisMedia',
		'bronchitis',
		'rubella',
		'downSyndrome',
		'convulsions',
		'pneumonia',
		'scarletFever',
		'epilepsy',
		'tonsillitis',
		'rash',
		'mumps',
		'accident'
	],
	MASSAGE: [
		'dizzyness',
		'std',
		'diabetes',
		'heartDisease',
		'asthma',
		'bloodPressure',
		'osteporosis',
		'epilepsy',
		'allergy',
		'skinDisease',
		'cancer',
		'rheumatism',
		'jointInjury',
		'migrane',
		'thrombosis',
		'hemophilia'
	]
}

export const PRE_INFO_FORM_INPUTS = {
	ADULT: [
		{
			component: 'Input',
			width: '--half',
			label: 'name',
			name: 'name',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--half',
			label: 'email',
			name: 'email',
			type: 'email'
		},
		{
			component: 'Input',
			width: '--half',
			label: 'address',
			name: 'address',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--quarter',
			label: 'postalCode',
			name: 'postalCode',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--quarter',
			label: 'city',
			name: 'city',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--half',
			label: 'phoneNumber',
			name: 'phoneNumber',
			type: 'tel'
		},
		{
			component: 'Date',
			width: '--quarter',
			label: 'dateOfBirth',
			name: 'dateOfBirth',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--quarter',
			label: 'timeOfBirth',
			name: 'timeOfBirth',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--half',
			label: 'birthPlace',
			name: 'birthPlace',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--medium',
			label: 'profession',
			name: 'profession',
			type: 'text'
		},
		{
			component: 'Checkbox',
			width: '--xs',
			label: 'worksNightShift',
			name: 'worksNightShift',
			type: 'checkbox'
		},
		{
			component: 'DoubleCheckbox',
			width: '--half',
			label: 'isPregnant',
			name: 'isPregnant',
			type: 'text'
		},
		{
			component: 'Date',
			width: '--half',
			label: 'dueDate',
			name: 'dueDate',
			type: 'text'
		},
		{
			component: 'DoubleCheckbox',
			width: '--half',
			label: 'isSmoking',
			name: 'isSmoking',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--half',
			label: 'intoxicantAmount',
			name: 'intoxicantAmount',
			type: 'text'
		},
		{
			component: 'DoubleCheckbox',
			width: '--half',
			label: 'isBloodDonor',
			name: 'isBloodDonor',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--half',
			label: 'mainReasonForService',
			name: 'mainReasonForService',
			type: 'text'
		}
	],
	CHILD: [
		{
			component: 'Input',
			width: '--half',
			label: 'childName',
			name: 'childName',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--half',
			label: 'email',
			name: 'email',
			type: 'email'
		},
		{
			component: 'Input',
			width: '--half',
			label: 'address',
			name: 'address',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--half',
			label: 'postalCode',
			name: 'postalCode',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--full',
			label: 'city',
			name: 'city',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--half',
			label: 'caretaker_name',
			name: 'name',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--half',
			label: 'phoneNumber',
			name: 'phoneNumber',
			type: 'tel'
		},
		{
			component: 'Date',
			width: '--quarter',
			label: 'dateOfBirth',
			name: 'dateOfBirth',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--quarter',
			label: 'timeOfBirth',
			name: 'timeOfBirth',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--half',
			label: 'birthPlace',
			name: 'birthPlace',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--full',
			label: 'mainReasonForService',
			name: 'mainReasonForService',
			type: 'text'
		}
	],
	MASSAGE: [
		{
			component: 'Input',
			width: '--half',
			label: 'name',
			name: 'name',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--half',
			label: 'socialSecurityNumber',
			name: 'socialSecurityNumber',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--half',
			label: 'address',
			name: 'address',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--half',
			label: 'postalCode',
			name: 'postalCode',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--full',
			label: 'city',
			name: 'city',
			type: 'text'
		},
		{
			component: 'Date',
			width: '--quarter',
			label: 'dateOfBirth',
			name: 'dateOfBirth',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--quarter',
			label: 'timeOfBirth',
			name: 'timeOfBirth',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--half',
			label: 'birthPlace',
			name: 'birthPlace',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--half',
			label: 'phoneNumber',
			name: 'phoneNumber',
			type: 'tel'
		},
		{
			component: 'Input',
			width: '--half',
			label: 'email',
			name: 'email',
			type: 'email'
		},
		{
			component: 'Input',
			width: '--full',
			label: 'profession',
			name: 'profession',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--full',
			label: 'hobbies',
			name: 'hobbies',
			type: 'text'
		},
		{
			component: 'Checkbox',
			width: '--half',
			label: 'surgeryInPastYear',
			name: 'surgeryInPastYear',
			type: 'text'
		},
		{
			component: 'Checkbox',
			width: '--half',
			label: 'usesPainKiller',
			name: 'usesPainKiller',
			type: 'text'
		}
	],
	PREGNANCY: [
		{
			component: 'Input',
			width: '--full',
			label: 'motherDetails',
			name: 'motherDetails',
			type: 'text'
		},
		// {
		// 	component: 'DoubleCheckbox',
		// 	width: '--quarter',
		// 	name: 'birthDetails',
		// 	type: 'text'
		// },
		{
			component: 'Input',
			width: '--medium',
			label: 'birthWeek',
			name: 'birthWeek',
			type: 'text'
		},
		{
			component: 'Checkbox',
			width: '--quarter',
			label: 'naturalBirth',
			name: 'naturalBirth',
			type: 'text'
		},
		{
			component: 'Checkbox',
			width: '--quarter',
			label: 'cSection',
			name: 'cSection',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--full',
			label: 'medicationDuringDelivery',
			name: 'medicationDuringDelivery',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--quarter',
			label: 'apgarPoint',
			name: 'apgarPoint',
			type: 'number'
		},
		{
			component: 'Input',
			width: '--full',
			label: 'somethingElse',
			name: 'somethingElse',
			type: 'text'
		},
		{
			component: 'DoubleCheckbox',
			width: '--half',
			label: 'isBabyBreastFed',
			name: 'isBabyBreastFed',
			type: 'text'
		},
		{
			component: 'Input',
			width: '--half',
			label: 'amountOfBreastFeeding',
			name: 'amountOfBreastFeeding',
			type: 'text'
		},
		{
			component: 'DoubleCheckbox',
			width: '--full',
			label: 'isBabyVaccinated',
			name: 'isBabyVaccinated',
			type: 'text'
		}
	]
}

export const PRE_INFO_FORM_TEXTAREAS = {
	ADULT: [
		{
			component: 'Textarea',
			width: '--half',
			name: 'moreDetails',
			type: 'text'
		},
		{
			component: 'Textarea',
			width: '--half',
			name: 'familyDiseases',
			type: 'text'
		},
		{
			component: 'Textarea',
			width: '--half',
			name: 'medication',
			type: 'text'
		}
	],
	CHILD: [
		{
			component: 'Textarea',
			width: '--half',
			name: 'somethingElse',
			type: 'text'
		},
		{
			component: 'Textarea',
			width: '--half',
			name: 'specificationDetails',
			type: 'text'
		},
		{
			component: 'Textarea',
			width: '--half',
			name: 'familyDiseases',
			type: 'text'
		},
		{
			component: 'Textarea',
			width: '--half',
			name: 'medication',
			type: 'text'
		}
	],
	MASSAGE: [
		{
			component: 'Textarea',
			width: '--half',
			name: 'conditionsToBeConsideredInMassage',
			type: 'text'
		},
		{
			component: 'Textarea',
			width: '--half',
			name: 'mainReasonForService',
			type: 'text'
		}
	],
	PET: [
		{
			component: 'Textarea',
			width: '--half',
			name: 'petDescription',
			type: 'text'
		}
	]
}

export const CONCEPTION_TYPE = [
	'natural',
	'ivf',
	'insemination',
	'microinjection',
	'fridgeEmbryo'
]

export const PRE_INFO_FORM_TYPES = {
	'acupuncture/adult': 'ACUPUNCTURE_ADULT',
	'acupuncture/child': 'ACUPUNCTURE_CHILD',
	'acupuncture/pet': 'ACUPUNCTURE_PET',
	'massage/adult': 'MASSAGE',
	'massage/child': 'MASSAGE',
	'massage/pet': 'MASSAGE'
}
export const ERRORS = {
	1000: 'BAD REQUEST',
	1001: 'EXISTING ENTITY',
	1002: 'INVALID CREDENTIALS',
	1003: 'INVALID TOKEN',
	1004: 'no_permission',
	1005: 'UNAUTHORIZED',
	1006: 'invalid_refresh_token',
	1007: 'INVALID KEY SPECIFIED',
	// User
	2000: 'email_in_use',
	2001: 'user_not_found',
	// Calendar
	3001: 'calendar_not_found',
	3002: 'calendar_already_exists',
	// Pre Info
	4001: 'PRE INFO NOT FOUND',
	// Booking
	5001: 'booking_not_found',
	5002: 'TIME UNAVAILABLE',
	// Circle of own situation
	6001: 'COOS NOT FOUND',
	// Treatment Plan
	7001: 'treatment_plan_not_found',
	7002: 'treatment_plan_already_exists',
	// Location
	8001: 'LOCATION NOT FOUND',
	// Company
	9001: 'COMPANY NOT FOUND'
}

// notification
export const NOTIFICATION_TYPE = {
	success: 'success',
	error: 'error',
	warning: 'warning',
	info: 'info'
}

export const GENDER = [
	{ value: 'MALE', label: 'Male' },
	{ value: 'FEMALE', label: 'Female' }
]

export const CITIES = [
	'Alajärvi',
	'Alavieska',
	'Alavus',
	'Asikkala',
	'Askola',
	'Aura',
	'Akaa',
	'Brändö',
	'Eckerö',
	'Enonkoski',
	'Enontekiö',
	'Espoo',
	'Eura',
	'Eurajoki',
	'Evijärvi',
	'Finström',
	'Forssa',
	'Föglö',
	'Geta',
	'Haapajärvi',
	'Haapavesi',
	'Hailuoto',
	'Halsua',
	'Hamina',
	'Hammarland',
	'Hankasalmi',
	'Hanko',
	'Harjavalta',
	'Hartola',
	'Hattula',
	'Hausjärvi',
	'Heinävesi',
	'Helsinki',
	'Hirvensalmi',
	'Hollola',
	'Huittinen',
	'Humppila',
	'Hyrynsalmi',
	'Hyvinkää',
	'Hämeenkyrö',
	'Hämeenlinna',
	'Heinola',
	'Ii',
	'Iisalmi',
	'Iitti',
	'Ikaalinen',
	'Ilmajoki',
	'Ilomantsi',
	'Inari',
	'Inkoo',
	'Isojoki',
	'Isokyrö',
	'Imatra',
	'Janakkala',
	'Joensuu',
	'Jokioinen',
	'Jomala',
	'Joroinen',
	'Joutsa',
	'Juuka',
	'Juupajoki',
	'Juva',
	'Jyväskylä',
	'Jämijärvi',
	'Jämsä',
	'Järvenpää',
	'Kaarina',
	'Kaavi',
	'Kajaani',
	'Kalajoki',
	'Kangasala',
	'Kangasniemi',
	'Kankaanpää',
	'Kannonkoski',
	'Kannus',
	'Karijoki',
	'Karkkila',
	'Karstula',
	'Karvia',
	'Kaskinen',
	'Kauhajoki',
	'Kauhava',
	'Kauniainen',
	'Kaustinen',
	'Keitele',
	'Kemi',
	'Keminmaa',
	'Kempele',
	'Kerava',
	'Keuruu',
	'Kihniö',
	'Kinnula',
	'Kirkkonummi',
	'Kitee',
	'Kittilä',
	'Kiuruvesi',
	'Kivijärvi',
	'Kokemäki',
	'Kokkola',
	'Kolari',
	'Konnevesi',
	'Kontiolahti',
	'Korsnäs',
	'Koski Tl',
	'Kotka',
	'Kouvola',
	'Kristiinankaupunki',
	'Kruunupyy',
	'Kuhmo',
	'Kuhmoinen',
	'Kumlinge',
	'Kuopio',
	'Kuortane',
	'Kurikka',
	'Kustavi',
	'Kuusamo',
	'Kyyjärvi',
	'Kärkölä',
	'Kärsämäki',
	'Kökar',
	'Kemijärvi',
	'Kemiönsaari',
	'Lahti',
	'Laihia',
	'Laitila',
	'Lapinlahti',
	'Lappajärvi',
	'Lappeenranta',
	'Lapinjärvi',
	'Lapua',
	'Laukaa',
	'Lemi',
	'Lemland',
	'Lempäälä',
	'Leppävirta',
	'Lestijärvi',
	'Lieksa',
	'Lieto',
	'Liminka',
	'Liperi',
	'Loimaa',
	'Loppi',
	'Loviisa',
	'Luhanka',
	'Lumijoki',
	'Lumparland',
	'Luoto',
	'Luumäki',
	'Lohja',
	'Parainen',
	'Maalahti',
	'Maarianhamina',
	'Marttila',
	'Masku',
	'Merijärvi',
	'Merikarvia',
	'Miehikkälä',
	'Mikkeli',
	'Muhos',
	'Multia',
	'Muonio',
	'Mustasaari',
	'Muurame',
	'Mynämäki',
	'Myrskylä',
	'Mäntsälä',
	'Mäntyharju',
	'Mänttä-Vilppula',
	'Naantali',
	'Nakkila',
	'Nivala',
	'Nokia',
	'Nousiainen',
	'Nurmes',
	'Nurmijärvi',
	'Närpiö',
	'Orimattila',
	'Oripää',
	'Orivesi',
	'Oulainen',
	'Oulu',
	'Outokumpu',
	'Padasjoki',
	'Paimio',
	'Paltamo',
	'Parikkala',
	'Parkano',
	'Pelkosenniemi',
	'Perho',
	'Pertunmaa',
	'Petäjävesi',
	'Pieksämäki',
	'Pielavesi',
	'Pietarsaari',
	'Pedersören kunta',
	'Pihtipudas',
	'Pirkkala',
	'Polvijärvi',
	'Pomarkku',
	'Pori',
	'Pornainen',
	'Posio',
	'Pudasjärvi',
	'Pukkila',
	'Punkalaidun',
	'Puolanka',
	'Puumala',
	'Pyhtää',
	'Pyhäjoki',
	'Pyhäjärvi',
	'Pyhäntä',
	'Pyhäranta',
	'Pälkäne',
	'Pöytyä',
	'Porvoo',
	'Raahe',
	'Raisio',
	'Rantasalmi',
	'Ranua',
	'Rauma',
	'Rautalampi',
	'Rautavaara',
	'Rautjärvi',
	'Reisjärvi',
	'Riihimäki',
	'Ristijärvi',
	'Rovaniemi',
	'Ruokolahti',
	'Ruovesi',
	'Rusko',
	'Rääkkylä',
	'Raasepori',
	'Saarijärvi',
	'Salla',
	'Salo',
	'Saltvik',
	'Sauvo',
	'Savitaipale',
	'Savonlinna',
	'Savukoski',
	'Seinäjoki',
	'Sievi',
	'Siikainen',
	'Siikajoki',
	'Siilinjärvi',
	'Simo',
	'Sipoo',
	'Siuntio',
	'Sodankylä',
	'Soini',
	'Somero',
	'Sonkajärvi',
	'Sotkamo',
	'Sottunga',
	'Sulkava',
	'Sund',
	'Suomussalmi',
	'Suonenjoki',
	'Sysmä',
	'Säkylä',
	'Vaala',
	'Sastamala',
	'Siikalatva',
	'Taipalsaari',
	'Taivalkoski',
	'Taivassalo',
	'Tammela',
	'Tampere',
	'Tervo',
	'Tervola',
	'Teuva',
	'Tohmajärvi',
	'Toholampi',
	'Toivakka',
	'Tornio',
	'Turku',
	'Pello',
	'Tuusniemi',
	'Tuusula',
	'Tyrnävä',
	'Ulvila',
	'Urjala',
	'Utajärvi',
	'Utsjoki',
	'Uurainen',
	'Uusikaarlepyy',
	'Uusikaupunki',
	'Vaasa',
	'Valkeakoski',
	'Vantaa',
	'Varkaus',
	'Vehmaa',
	'Vesanto',
	'Vesilahti',
	'Veteli',
	'Vieremä',
	'Vihti',
	'Viitasaari',
	'Vimpeli',
	'Virolahti',
	'Virrat',
	'Vårdö',
	'Vöyri',
	'Ylitornio',
	'Ylivieska',
	'Ylöjärvi',
	'Ypäjä',
	'Ähtäri',
	'Äänekoski'
]

/* Years 1800-1899 */
export const TK19 = [
	'0x30baa3',
	'0x56ab50',
	'0x422ba0',
	'0x2cab61',
	'0x52a370',
	'0x3c51e8',
	'0x60d160',
	'0x4ae4b0',
	'0x376926',
	'0x58daa0',
	'0x445b50',
	'0x3116d2',
	'0x562ae0',
	'0x3ea2e0',
	'0x28e2d2',
	'0x4ec950',
	'0x38d556',
	'0x5cb520',
	'0x46b690',
	'0x325da4',
	'0x5855d0',
	'0x4225d0',
	'0x2ca5b3',
	'0x52a2b0',
	'0x3da8b7',
	'0x60a950',
	'0x4ab4a0',
	'0x35b2a5',
	'0x5aad50',
	'0x4455b0',
	'0x302b74',
	'0x562570',
	'0x4052f9',
	'0x6452b0',
	'0x4e6950',
	'0x386d56',
	'0x5e5aa0',
	'0x46ab50',
	'0x3256d4',
	'0x584ae0',
	'0x42a570',
	'0x2d4553',
	'0x50d2a0',
	'0x3be8a7',
	'0x60d550',
	'0x4a5aa0',
	'0x34ada5',
	'0x5a95d0',
	'0x464ae0',
	'0x2eaab4',
	'0x54a4d0',
	'0x3ed2b8',
	'0x64b290',
	'0x4cb550',
	'0x385757',
	'0x5e2da0',
	'0x4895d0',
	'0x324d75',
	'0x5849b0',
	'0x42a4b0',
	'0x2da4b3',
	'0x506a90',
	'0x3aad98',
	'0x606b50',
	'0x4c2b60',
	'0x359365',
	'0x5a9370',
	'0x464970',
	'0x306964',
	'0x52e4a0',
	'0x3cea6a',
	'0x62da90',
	'0x4e5ad0',
	'0x392ad6',
	'0x5e2ae0',
	'0x4892e0',
	'0x32cad5',
	'0x56c950',
	'0x40d4a0',
	'0x2bd4a3',
	'0x50b690',
	'0x3a57a7',
	'0x6055b0',
	'0x4c25d0',
	'0x3695b5',
	'0x5a92b0',
	'0x44a950',
	'0x2ed954',
	'0x54b4a0',
	'0x3cb550',
	'0x286b52',
	'0x4e55b0',
	'0x3a2776',
	'0x5e2570',
	'0x4852b0',
	'0x32aaa5',
	'0x56e950',
	'0x406aa0',
	'0x2abaa3',
	'0x50ab50'
]

export const TK20 = [
	'0x3c4bd8',
	'0x624ae0',
	'0x4ca570',
	'0x3854d5',
	'0x5cd260',
	'0x44d950',
	'0x315554',
	'0x5656a0',
	'0x409ad0',
	'0x2a55d2',
	'0x504ae0',
	'0x3aa5b6',
	'0x60a4d0',
	'0x48d250',
	'0x33d255',
	'0x58b540',
	'0x42d6a0',
	'0x2cada2',
	'0x5295b0',
	'0x3f4977',
	'0x644970',
	'0x4ca4b0',
	'0x36b4b5',
	'0x5c6a50',
	'0x466d50',
	'0x312b54',
	'0x562b60',
	'0x409570',
	'0x2c52f2',
	'0x504970',
	'0x3a6566',
	'0x5ed4a0',
	'0x48ea50',
	'0x336a95',
	'0x585ad0',
	'0x442b60',
	'0x2f86e3',
	'0x5292e0',
	'0x3dc8d7',
	'0x62c950',
	'0x4cd4a0',
	'0x35d8a6',
	'0x5ab550',
	'0x4656a0',
	'0x31a5b4',
	'0x5625d0',
	'0x4092d0',
	'0x2ad2b2',
	'0x50a950',
	'0x38b557',
	'0x5e6ca0',
	'0x48b550',
	'0x355355',
	'0x584da0',
	'0x42a5b0',
	'0x2f4573',
	'0x5452b0',
	'0x3ca9a8',
	'0x60e950',
	'0x4c6aa0',
	'0x36aea6',
	'0x5aab50',
	'0x464b60',
	'0x30aae4',
	'0x56a570',
	'0x405260',
	'0x28f263',
	'0x4ed940',
	'0x38db47',
	'0x5cd6a0',
	'0x4896d0',
	'0x344dd5',
	'0x5a4ad0',
	'0x42a4d0',
	'0x2cd4b4',
	'0x52b250',
	'0x3cd558',
	'0x60b540',
	'0x4ab5a0',
	'0x3755a6',
	'0x5c95b0',
	'0x4649b0',
	'0x30a974',
	'0x56a4b0',
	'0x40aa50',
	'0x29aa52',
	'0x4e6d20',
	'0x39ad47',
	'0x5eab60',
	'0x489370',
	'0x344af5',
	'0x5a4970',
	'0x4464b0',
	'0x2c74a3',
	'0x50ea50',
	'0x3d6a58',
	'0x6256a0',
	'0x4aaad0',
	'0x3696d5',
	'0x5c92e0'
] /* Years 1900-1999 */

export const TK21 = [
	'0x46c960',
	'0x2ed954',
	'0x54d4a0',
	'0x3eda50',
	'0x2a7552',
	'0x4e56a0',
	'0x38a7a7',
	'0x5ea5d0',
	'0x4a92b0',
	'0x32aab5',
	'0x58a950',
	'0x42b4a0',
	'0x2cbaa4',
	'0x50ad50',
	'0x3c55d9',
	'0x624ba0',
	'0x4ca5b0',
	'0x375176',
	'0x5c5270',
	'0x466930',
	'0x307934',
	'0x546aa0',
	'0x3ead50',
	'0x2a5b52',
	'0x504b60',
	'0x38a6e6',
	'0x5ea4e0',
	'0x48d260',
	'0x32ea65',
	'0x56d520',
	'0x40daa0',
	'0x2d56a3',
	'0x5256d0',
	'0x3c4afb',
	'0x6249d0',
	'0x4ca4d0',
	'0x37d0b6',
	'0x5ab250',
	'0x44b520',
	'0x2edd25',
	'0x54b5a0',
	'0x3e55d0',
	'0x2a55b2',
	'0x5049b0',
	'0x3aa577',
	'0x5ea4b0',
	'0x48aa50',
	'0x33b255',
	'0x586d20',
	'0x40ad60',
	'0x2d4b63',
	'0x525370',
	'0x3e49e8',
	'0x60c970',
	'0x4c54b0',
	'0x3768a6',
	'0x5ada50',
	'0x445aa0',
	'0x2fa6a4',
	'0x54aad0',
	'0x4052e0',
	'0x28d2e3',
	'0x4ec950',
	'0x38d557',
	'0x5ed4a0',
	'0x46d950',
	'0x325d55',
	'0x5856a0',
	'0x42a6d0',
	'0x2c55d4',
	'0x5252b0',
	'0x3ca9b8',
	'0x62a930',
	'0x4ab490',
	'0x34b6a6',
	'0x5aad50',
	'0x4655a0',
	'0x2eab64',
	'0x54a570',
	'0x4052b0',
	'0x2ab173',
	'0x4e6930',
	'0x386b37',
	'0x5e6aa0',
	'0x48ad50',
	'0x332ad5',
	'0x582b60',
	'0x42a570',
	'0x2e52e4',
	'0x50d160',
	'0x3ae958',
	'0x60d520',
	'0x4ada90',
	'0x355aa6',
	'0x5a56d0',
	'0x462ae0',
	'0x30a9d4',
	'0x54a2d0',
	'0x3ed150',
	'0x28e952'
] /* Years 2000-2099 */

export const TK22 = [
	'0x4eb520',
	'0x38d727',
	'0x5eada0',
	'0x4a55b0',
	'0x362db5',
	'0x5a45b0',
	'0x44a2b0',
	'0x2eb2b4',
	'0x54a950',
	'0x3cb559',
	'0x626b20',
	'0x4cad50',
	'0x385766',
	'0x5c5370',
	'0x484570',
	'0x326574',
	'0x5852b0',
	'0x406950',
	'0x2a7953',
	'0x505aa0',
	'0x3baaa7',
	'0x5ea6d0',
	'0x4a4ae0',
	'0x35a2e5',
	'0x5aa550',
	'0x42d2a0',
	'0x2de2a4',
	'0x52d550',
	'0x3e5abb',
	'0x6256a0',
	'0x4c96d0',
	'0x3949b6',
	'0x5e4ab0',
	'0x46a8d0',
	'0x30d4b5',
	'0x56b290',
	'0x40b550',
	'0x2a6d52',
	'0x504da0',
	'0x3b9567',
	'0x609570',
	'0x4a49b0',
	'0x34a975',
	'0x5a64b0',
	'0x446a90',
	'0x2cba94',
	'0x526b50',
	'0x3e2b60',
	'0x28ab61',
	'0x4c9570',
	'0x384ae6',
	'0x5cd160',
	'0x46e4a0',
	'0x2eed25',
	'0x54da90',
	'0x405b50',
	'0x2c36d3',
	'0x502ae0',
	'0x3a93d7',
	'0x6092d0',
	'0x4ac950',
	'0x32d556',
	'0x58b4a0',
	'0x42b690',
	'0x2e5d94',
	'0x5255b0',
	'0x3e25fa',
	'0x6425b0',
	'0x4e92b0',
	'0x36aab6',
	'0x5c6950',
	'0x4674a0',
	'0x31b2a5',
	'0x54ad50',
	'0x4055a0',
	'0x2aab73',
	'0x522570',
	'0x3a5377',
	'0x6052b0',
	'0x4a6950',
	'0x346d56',
	'0x585aa0',
	'0x42ab50',
	'0x2e56d4',
	'0x544ae0',
	'0x3ca570',
	'0x2864d2',
	'0x4cd260',
	'0x36eaa6',
	'0x5ad550',
	'0x465aa0',
	'0x30ada5',
	'0x5695d0',
	'0x404ad0',
	'0x2aa9b3',
	'0x50a4d0',
	'0x3ad2b7',
	'0x5eb250',
	'0x48b540',
	'0x33d556'
] /* Years 2100-2199 */

export const CAN = [
	'Giap',
	'At',
	'Binh',
	'Dinh',
	'Mau',
	'Ky',
	'Canh',
	'Tan',
	'Nham',
	'Quy'
]

export const CHI = [
	'Ty',
	'Suu',
	'Dan',
	'Mao',
	'Thin',
	'Ti',
	'Ngo',
	'Mui',
	'Than',
	'Dau',
	'Tuat',
	'Hoi'
]

export const errorMessages = {
	1002: 'invalid_authentication',
	1004: 'no_permission',
	1006: 'invalid_refresh_token',
	2000: 'email_in_use',
	2001: 'user_not_found',
	3001: 'calendar_not_found',
	3002: 'calendar_already_exists',
	4001: 'pre_info_not_found',
	5001: 'booking_not_found',
	5002: 'time_unavailable',
	6001: 'coos_not_found',
	7001: 'treatment_plan_not_found',
	7002: 'treatment_plan_already_exists'
}

export const COLORS = [
	'#8B0000',
	'#FF0000',
	'#FA8072',
	'#FF8C00',
	'#B8860B',
	'#808000',
	'#9ACD32',
	'#32CD32',
	'#008080',
	'#00CED1',
	'#4682B4',
	'#1E90FF',
	'#0000FF',
	'#8A2BE2',
	'#9370DB',
	'#8B008B',
	'#BA55D3',
	'#EE82EE',
	'#FF1493',
	'#F4A460',
	'#BC8F8F'
]
