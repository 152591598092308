/**
 *  Replace string params with matching value
 *
 * @param  {Array} arrayParams
 * @param  {Array} arrayValue
 * @param  {String} uri
 *
 * @return {String} new URI
 */
export const replaceParamsUri = (arrayParams, arrayValue, uri) => {
	const cloneUri = arrayParams.reduce(
		(prevUri, currParams, currIndex) =>
			prevUri.replace(`{${currParams}}`, arrayValue[currIndex]),
		uri
	)
	return cloneUri
}
