import { bookingTypes } from '../actions/types'

const initialState = {
	booking: [],

	error: null,
	loading: false
}

export const bookingReducer = (state = initialState, action) => {
	switch (action.type) {
		case bookingTypes.getAllBookingById:
			return {
				...state,
				loading: true
			}
		case bookingTypes.getAllBookingByIdSuccess:
			return {
				...state,
				booking: action.payload.data,
				loading: false
			}

		default:
			return state
	}
}
