/**
 * Core Layout component
 * Wrap the root component with layout UI components
 * e.g Navigation, Footer, Modal, Alert...
 *
 * @author <@vertics.co>
 *
 */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

// Actions
import { userActions } from '../../../actions'

// Selectors
import { userSelectors, loadingSelectors, history } from '../../../reducers'

// Hooks
import { AuthorizationContext, useAuthorization } from '../../../hooks'
import Router from '../../../components/Router'
import { ROUTER_PATH } from '../../../constants'
import UserErrorBox from '../UserErrorBox'

const CoreLayout = () => {
	const dispatch = useDispatch()
	const user = useSelector(state => userSelectors.getUser(state.user))
	const userErrors = useSelector(state =>
		userSelectors.getUserErrors(state.user)
	)
	const loading = useSelector(state =>
		loadingSelectors.getAuthLoading(state.loading)
	)
	const path = useSelector(state => state.router.location.pathame)

	const { isAuthenticated } = useAuthorization()

	React.useEffect(() => {
		if (isAuthenticated && !user) {
			// const localUserId = localStorage.getItem('userId')
			const localUserId = sessionStorage.getItem('userId')
			dispatch(userActions.getUserById(localUserId)).catch(() => {
				// localStorage.removeItem('refreshToken')
				// localStorage.removeItem('userId')
				sessionStorage.removeItem('refreshToken')
				sessionStorage.removeItem('userId')
				history.push(ROUTER_PATH.LOGIN)
			})
		}
	}, [])

	return (
		<AuthorizationContext.Provider value={{ user, loading }}>
			<Router />
			{userErrors.length > 0 ? <UserErrorBox /> : null}
		</AuthorizationContext.Provider>
	)
}

export default CoreLayout
