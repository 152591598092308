import { treatmentNotesTypes } from "../actions/types"

const initialState = {
    treatmentNotes: [],
    error: null,
    loading: false
}

export const treatmentNotesReducer = (state = initialState, action) => {
    switch (action.type) {
        case treatmentNotesTypes.getTreatmentNotesById:
            return {
                ...state,
                loading: true
            }
        case treatmentNotesTypes.getTreatmentNotesByIdSuccess:
            return {
                ...state,
                treatmentNotes: action.payload.data,
                loading: false
            }
        case treatmentNotesTypes.createNewTreatmentNotes:
            return {
                ...state,
                loading: true
            }
        case treatmentNotesTypes.createNewTreatmentNotesSuccess:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}