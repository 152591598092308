/**
 *
 * @author Trung H <trung@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { userActions } from '../../../actions'
import { userSelectors } from '../../../reducers'

import { ROUTER_PATH, USER_ROLE } from '../../../constants'

// Components
import Button from '../Button'
import Snackbar from '../../components/Snackbar'

const UnauthorizedLayout = ({ children, userRole, updateRole }) => {
	const history = useHistory()
	const [currentPath, setCurrentPath] = useState(history.location.pathname)
	useEffect(() => {
		setCurrentPath(history.location.pathname)
	}, [history.location.pathname])
	const toLoginPage = () => history.push(ROUTER_PATH.LOGIN)
	const changeRoleToUser = () => updateRole(USER_ROLE.USER)
	const changeRoleToServiceProvider = () =>
		updateRole(USER_ROLE.SERVICE_PROVIDER)
	const renderUnauthorizedNav = () => {
		if (currentPath !== ROUTER_PATH.LOGIN) {
			return <Button text='Etusivu' onClick={toLoginPage} />
		}
	}
	return (
		<main className='unauthorized-layout'>
			<img
				className='unauthorized-layout__bg-up'
				src='../../../assets/web-bgup.png'
				alt='web top background'
			/>
			<img
				className='unauthorized-layout__bg-down'
				src='../../../assets/web-bgdown.png'
				alt='web down background'
			/>
			<div className='unauthorized-layout__nav'>{renderUnauthorizedNav()}</div>
			<div className='unauthorized-layout__logo-container'>
				<img
					className='unauthorized-layout__logo'
					src='../../../assets/Akupunktioklinikka_logo.png'
					alt='web down background'
				/>
				<h1 className='unauthorized-layout__logo-brand'>AKUPUNKTIOKLINIKKA</h1>
			</div>
			<div className='unauthorized-layout__body'>{children}</div>
			<Snackbar />
		</main>
	)
}

export default connect(
	state => ({
		userRole: userSelectors.getRole(state.user)
	}),
	dispatch => ({
		logout: () => dispatch(userActions.signOut()),
		updateRole: role => dispatch(userActions.updateRole(role))
	})
)(UnauthorizedLayout)
