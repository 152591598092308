/**
 * Declare all types and export
 *
 * @author trung <trung@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

// Login

export const GET_USER_BY_ID = 'GET_USER_BY_ID'
export const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS'
export const GET_USER_BY_ID_FAIL = 'GET_USER_BY_ID_FAIL'

export const SIGN_IN = 'SIGN_IN'
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
export const SIGN_IN_FAIL = 'SIGN_IN_FAIL'

export const TWOFA_SIGN_IN = 'TWOFA_SIGN_IN'
export const TWOFA_SIGN_IN_SUCCESS = 'TWOFA_SIGN_IN_SUCCESS'
export const TWOFA_SIGN_IN_FAIL = 'TWOFA_SIGN_IN_FAIL'

export const SIGN_OUT = 'SIGN_OUT'
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS'
export const SIGN_OUT_FAIL = 'SIGN_OUT_FAIL'

export const SIGN_UP = 'SIGN_UP'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const SIGN_UP_FAIL = 'SIGN_UP_FAIL'

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL'

export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'

export const AUTHORIZE_PERSON = 'AUTHORIZE_PERSON'
export const AUTHORIZE_PERSON_SUCCESS = 'AUTHORIZE_PERSON_SUCCESS'
export const AUTHORIZE_PERSON_FAIL = 'AUTHORIZE_PERSON_FAIL'

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'

export const UPDATE_ROLE = 'UPDATE_ROLE'
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS'
export const UPDATE_ROLE_FAIL = 'UPDATE_ROLE_FAIL'

// Admin

export const ACTIVATE_PROVIDER = 'ACTIVATE_PROVIDER'
export const ACTIVATE_PROVIDER_SUCCESS = 'ACTIVATE_PROVIDER_SUCCESS'
export const ACTIVATE_PROVIDER_FAIL = 'ACTIVATE_PROVIDER_FAIL'

export const DEACTIVATE_PROVIDER = 'DEACTIVATE_PROVIDER'
export const DEACTIVATE_PROVIDER_SUCCESS = 'DEACTIVATE_PROVIDER_SUCCESS'
export const DEACTIVATE_PROVIDER_FAIL = 'DEACTIVATE_PROVIDER_FAIL'

export const GET_PROVIDERS_ADMIN = 'GET_PROVIDERS_ADMIN'
export const GET_PROVIDERS_ADMIN_SUCCESS = 'GET_PROVIDERS_ADMIN_SUCCESS'
export const GET_PROVIDERS_ADMIN_FAIL = 'GET_PROVIDERS_ADMIN_FAIL'

export const VERIFY_PROVIDER = 'VERIFY_PROVIDER'
export const VERIFY_PROVIDER_SUCCESS = 'VERIFY_PROVIDER_SUCCESS'
export const VERIFY_PROVIDER_FAIL = 'VERIFY_PROVIDER_FAIL'

export const DELETE_SECRET = 'DELETE_SECRET'
export const DELETE_SECRET_SUCCESS = 'DELETE_SECRET_SUCCESS'
export const DELETE_SECRET_FAIL = 'DELETE_SECRET_FAIL'

// User
export const GET_NEW_ACCESS_TOKEN = 'GET_NEW_ACCESS_TOKEN'
export const GET_NEW_ACCESS_TOKEN_SUCCESS = 'GET_NEW_ACCESS_TOKEN_SUCCESS'
export const GET_NEW_ACCESS_TOKEN_FAIL = 'GET_NEW_ACCESS_TOKEN_FAIL'

export const GET_NEW_REFRESH_TOKEN = 'GET_NEW_REFRESH_TOKEN'
export const GET_NEW_REFRESH_TOKEN_SUCCESS = 'GET_NEW_REFRESH_TOKEN_SUCCESS'
export const GET_NEW_REFRESH_TOKEN_FAIL = 'GET_NEW_REFRESH_TOKEN_FAIL'

export const GET_CONSENTED_PROVIDERS = 'GET_CONSENTED_PROVIDERS'
export const GET_CONSENTED_PROVIDERS_SUCCESS = 'GET_CONSENTED_PROVIDERS_SUCCESS'
export const GET_CONSENTED_PROVIDERS_FAIL = 'GET_CONSENTED_PROVIDERS_FAIL'

export const COPY_CALENDAR = 'COPY_CALENDAR'
export const COPY_CALENDAR_SUCCESS = 'COPY_CALENDAR_SUCCESS'
export const COPY_CALENDAR_FAIL = 'COPY_CALENDAR_FAIL'

export const CREATE_CALENDAR = 'CREATE_CALENDAR'
export const CREATE_CALENDAR_SUCCESS = 'CREATE_CALENDAR_SUCCESS'
export const CREATE_CALENDAR_FAIL = 'CREATE_CALENDAR_FAIL'

export const GET_AVAILABLE_BOOKINGS = 'GET_AVAILABLE_BOOKINGS'
export const GET_AVAILABLE_BOOKINGS_SUCCESS = 'GET_AVAILABLE_BOOKINGS_SUCCESS'
export const GET_AVAILABLE_BOOKINGS_FAIL = 'GET_AVAILABLE_BOOKINGS_FAIL'

export const GET_ALLAVAILABLE_BOOKINGS = 'GET_ALLAVAILABLE_BOOKINGS'
export const GET_ALLAVAILABLE_BOOKINGS_SUCCESS = 'GET_ALLAVAILABLE_BOOKINGS_SUCCESS'
export const GET_ALLAVAILABLE_BOOKINGS_FAIL = 'GET_ALLAVAILABLE_BOOKINGS_FAIL'

export const GET_BOOKINGS = 'GET_BOOKINGS'
export const GET_BOOKINGS_SUCCESS = 'GET_BOOKINGS_SUCCESS'
export const GET_BOOKINGS_FAIL = 'GET_BOOKINGS_FAIL'

export const GET_ALL_BOOKINGS = 'GET_ALL_BOOKINGS'
export const GET_ALL_BOOKINGS_SUCCESS = 'GET_ALL_BOOKINGS_SUCCESS'
export const GET_ALL_BOOKINGS_FAIL = 'GET_ALL_BOOKINGS_FAIL'

export const CREATE_CIRCLE_OF_OWN_SITUATION = 'CREATE_CIRCLE_OF_OWN_SITUATION'
export const CREATE_CIRCLE_OF_OWN_SITUATION_SUCCESS =
	'CREATE_CIRCLE_OF_OWN_SITUATION_SUCCESS'
export const CREATE_CIRCLE_OF_OWN_SITUATION_FAIL =
	'CREATE_CIRCLE_OF_OWN_SITUATION_FAIL'

export const GET_CIRCLE_OF_OWN_SITUATION = 'GET_CIRCLE_OF_OWN_SITUATION'
export const GET_CIRCLE_OF_OWN_SITUATION_SUCCESS =
	'GET_CIRCLE_OF_OWN_SITUATION_SUCCESS'
export const GET_CIRCLE_OF_OWN_SITUATION_FAIL =
	'GET_CIRCLE_OF_OWN_SITUATION_FAIL'

export const CREATE_LOCATION = 'CREATE_LOCATION'
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS'
export const CREATE_LOCATION_FAIL = 'CREATE_LOCATION_FAIL'

export const GET_TREATMENT_PLANS = 'GET_TREATMENT_PLANS'
export const GET_TREATMENT_PLANS_SUCCESS = 'GET_TREATMENT_PLANS_SUCCESS'
export const GET_TREATMENT_PLANS_FAIL = 'GET_TREATMENT_PLANS_FAIL'

export const GET_TREATMENT_PLANS_CUSTOMER = 'GET_TREATMENT_PLANS_CUSTOMER'
export const GET_TREATMENT_PLANS_CUSTOMER_SUCCESS =
	'GET_TREATMENT_PLANS_CUSTOMER_SUCCESS'
export const GET_TREATMENT_PLANS_CUSTOMER_FAIL =
	'GET_TREATMENT_PLANS_CUSTOMER_FAIL'

export const GET_PRE_INFO = 'GET_PRE_INFO'
export const GET_PRE_INFO_SUCCESS = 'GET_PRE_INFO_SUCCESS'
export const GET_PRE_INFO_FAIL = 'GET_PRE_INFO_FAIL'

export const GET_USERS_BY_ROLE = 'GET_USERS_BY_ROLE'
export const GET_USERS_BY_ROLE_SUCCESS = 'GET_USERS_BY_ROLE_SUCCESS'
export const GET_USERS_BY_ROLE_FAIL = 'GET_USERS_BY_ROLE_FAIL'

export const GET_CALENDAR = 'GET_CALENDAR'
export const GET_CALENDAR_SUCCESS = 'GET_CALENDAR_SUCCESS'
export const GET_CALENDAR_FAIL = 'GET_CALENDAR_FAIL'

export const GET_WEEK_CALENDAR = 'GET_WEEK_CALENDAR'
export const GET_WEEK_CALENDAR_SUCCESS = 'GET_WEEK_CALENDAR_SUCCESS'
export const GET_WEEK_CALENDAR_FAIL = 'GET_WEEK_CALENDAR_FAIL'

export const DELETE_CALENDAR = 'DELETE_CALENDAR'
export const DELETE_CALENDAR_SUCCESS = 'DELETE_CALENDAR_SUCCESS'
export const DELETE_CALENDAR_FAIL = 'DELETE_CALENDAR_FAIL'

export const POST_PRE_INFO = 'POST_PRE_INFO'
export const POST_PRE_INFO_SUCCESS = 'POST_PRE_INFO_SUCCESS'
export const POST_PRE_INFO_FAIL = 'POST_PRE_INFO_FAIL'

export const RESET_AVAILABLE_BOOKING = 'RESET_AVAILABLE_BOOKING'

export const RESET_USER_ERRORS = 'RESET_USER_ERRORS'

export const SEND_PRE_INFO = 'SEND_PRE_INFO'
export const SEND_PRE_INFO_SUCCESS = 'SEND_PRE_INFO_SUCCESS'
export const SEND_PRE_INFO_FAIL = 'SEND_PRE_INFO_FAIL'

export const UPDATE_CALENDAR = 'UPDATE_CALENDAR'
export const UPDATE_CALENDAR_SUCCESS = 'UPDTATE_CALENDAR_SUCCESS'
export const UPDATE_CALENDAR_FAIL = 'UPDTATE_CALENDAR_FAIL'

export const UPDATE_BOOKING_CALENDAR = 'UPDATE_BOOKING_CALENDAR'
export const UPDATE_BOOKING_CALENDAR_SUCCESS = 'UPDATE_BOOKING_CALENDAR_SUCCESS'
export const UPDATE_BOOKING_CALENDAR_FAIL = 'UPDATE_BOOKING_CALENDAR_FAIL'

export const UPDATE_COMPANY = 'UPDATE_COMPANY'
export const UPDATE_COMPANY_FAIL = 'UPDATE_COMPANY_FAIL'
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS'

export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD'
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS'
export const UPDATE_USER_PASSWORD_FAIL = 'UPDATE_USER_PASSWORD_FAIL'

export const UPDATE_PRE_INFO = 'UPDATE_PRE_INFO'
export const UPDATE_PRE_INFO_SUCCESS = 'UPDATE_PRE_INFO_SUCCESS'
export const UPDATE_PRE_INFO_FAIL = 'UPDATE_PRE_INFO_FAIL'

// Appointments

export const CREATE_BOOKING = 'CREATE_BOOKING'
export const CREATE_BOOKING_SUCCESS = 'CREATE_BOOKING_SUCCESS'
export const CREATE_BOOKING_FAIL = 'CREATE_BOOKING_FAIL'

export const CREATE_SERVICE = 'CREATE_SERVICE'
export const CREATE_SERVICE_SUCCESS = 'CREATE_SERVICE_SUCCESS'
export const CREATE_SERVICE_FAIL = 'CREATE_SERVICE_FAIL'

export const EDIT_BOOKING = 'EDIT_BOOKING'
export const EDIT_BOOKING_SUCCESS = 'EDIT_BOOKING_SUCCESS'
export const EDIT_BOOKING_FAIL = 'EDIT_BOOKING_FAIL'

export const EDIT_BOOKING_STATUS = 'EDIT_BOOKING_STATUS'
export const EDIT_BOOKING_STATUS_SUCCESS = 'EDIT_BOOKING_STATUS_SUCCESS'
export const EDIT_BOOKING_STATUS_FAIL = 'EDIT_BOOKING_STATUS_FAIL'

export const REMOVE_BOOKING = 'REMOVE_BOOKING'
export const REMOVE_BOOKING_SUCCESS = 'REMOVE_BOOKING_SUCCESS'
export const REMOVE_BOOKING_FAIL = 'REMOVE_BOOKING_FAIL'

export const EDIT_SERVICE = 'EDIT_SERVICE'
export const EDIT_SERVICE_SUCCESS = 'EDIT_SERVICE_SUCCESS'
export const EDIT_SERVICE_FAIL = 'EDIT_SERVICE_FAIL'

export const GET_COMPANIES = 'GET_COMPANIES'
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS'
export const GET_COMPANIES_FAIL = 'GET_COMPANIES_FAIL'

export const GET_COMPANY_SERVICES = 'GET_COMPANY_SERVICES'
export const GET_COMPANY_SERVICES_SUCCESS = 'GET_COMPANY_SERVICES_SUCCESS'
export const GET_COMPANY_SERVICES_FAIL = 'GET_COMPANY_SERVICES_FAIL'

export const UPLOAD_USER_AVATAR = 'UPLOAD_USER_AVATAR'
export const UPLOAD_USER_AVATAR_SUCCESS = 'UPLOAD_USER_AVATAR_SUCCESS'
export const UPLOAD_USER_AVATAR_FAIL = 'UPLOAD_USER_AVATAR_FAIL'

// PreInfo
export const preInfoTypes = {
	getPreInfoById: 'PREINFO_GET_BY_ID',
	getPreInfoByIdSuccess: 'PREINFO_GET_BY_ID_SUCCESS',
	getPreInfoByIdFail: 'PREINFO_GET_BY_ID_FAIL'
}

// Patient
export const patientTypes = {
	initPatientInfo: 'INIT_PATIENT_INFO'
}

export const GET_PATIENT_COOS = 'GET_PATIENT_COOS'
export const GET_PATIENT_COOS_SUCCESS = 'GET_PATIENT_COOS_SUCCESS'
export const GET_PATIENT_COOS_FAIL = 'GET_PATIENT_COOS_FAIL'

export const treatmentPlanTypes = {
	createNewTreatmentPlan: 'NEW_TREATMENT_PLAN_CREATE',
	createNewTreatmentPlanSuccess: 'NEW_TREATMENT_PLAN_CREATE_SUCCESS',
	createNewTreatmentPlanFail: 'NEW_TREATMENT_PLAN_CREATE_FAIL',

	getTreatmentPlanById: 'TREATMENT_PLAN_GET_BY_ID',
	getTreatmentPlanByIdSuccess: 'TREATMENT_PLAN_GET_BY_ID_SUCCESS',
	getTreatmentPlanByIdFail: 'TREATMENT_PLAN_GET_BY_ID_FAIL',

	updateTreatmentPlan: 'UPDATE_TREATMENT_PLAN',
	updateTreatmentPlanSuccess: 'UPDATE_TREATMENT_PLAN_SUCCESS',
	updateTreatmentPlanFail: 'UPDATE_TREATMENT_PLAN_FAIL',

	deleteTreatmentPlan: 'DELETE_TREATMENT_PALN'
}

export const treatmentNotesTypes = {
	createNewTreatmentNotes: 'NEW_TREATMENT_NOTES_CREATE',
	createNewTreatmentNotesSuccess: 'NEW_TREATMENT_NOTES_CREATE_SUCCESS',
	createNewTreatmentNotesFail: 'NEW_TREATMENT_NOTES_CREATE_FAIL',

	getTreatmentNotesById: 'TREATMENT_NOTES_GET_BY_ID',
	getTreatmentNotesByIdSuccess: 'TREATMENT_NOTES_GET_BY_ID_SUCCESS',
	getTreatmentNotesByIdFail: 'TREATMENT_NOTES_GET_BY_ID_FAIL',

	updateTreatmentNotes: 'UPDATE_TREATMENT_NOTES',
	updateTreatmentNotesSuccess: 'UPDATE_TREATMENT_NOTES_SUCCESS',
	updateTreatmentNotesFail: 'UPDATE_TREATMENT_NOTES_FAIL'
}

export const twoFAtypes = {
	createTwoFA: 'TWO_FA',
	createTwoFASuccess: 'TWO_FA_SUCCESS',
	createTwoFAFail: 'TWO_FA_FAIL',

	addSecret: 'TWO_FA_SECRET',
	addSecretSuccess: 'TWO_FA_SECRET_SUCCESS',
	addSecretFail: 'TWO_FA_SECRET_FAIL'
	
}

export const bookingTypes = {
	getAllBookingById: 'BOOKING_GET_ALL_BY_ID',
	getAllBookingByIdSuccess: 'BOOKING_GET_ALL_BY_ID_SUCCESS',
	getAllBookingByIdFail: 'BOOKING_GET_ALL_BY_ID_FAIL',

	getAllBookingByCustomerId: 'BOOKING_GET_ALL_BY_CUSTOMER_ID',
	getAllBookingByCustomerIdSuccess: 'BOOKING_GET_ALL_BY_CUSTOMER_ID_SUCCESS',
	getAllBookingByCustomerIdFail: 'BOOKING_GET_ALL_BY_CUSTOMER_ID_FAIL',

	deleteBooking: 'BOOKING_DELETE_BOOKING',
	deleteBookingSuccess: 'BOOKING_DELETE_BOOKING_SUCCESS',
	deleteBookingFail: 'BOOKING_DELETE_BOOKING_FAIL'
}

// Notification
export const notificationTypes = {
	showNotification: 'NOTIFICATION_SHOW_NOTIFICATION',
	resetNotification: 'NOTIFICATION_RESET_NOTIFICATION'
}

export const companyTypes = {
	getServicesByCompany: 'GET_SERVICES_BY_COMPANY',
	getServicesByCompanySuccess: 'GET_SERVICES_BY_COMPANY_SUCCESS',
	getServicesByCompanyFail: 'GET_SERVICES_BY_COMPANY_FAIL',

	createCompanyService: 'CREATE_COMPANY_SERVICE',
	createCompanyServiceSuccess: 'CREATE_COMPANY_SERVICE_SUCCESS',
	createCompanyServiceFail: 'CREATE_COMPANY_SERVICE_FAIL',

	updateCompanyInfo: 'UPDATE_COMPANY_INFO',
	updateCompanyInfoSuccess: 'UPDATE_COMPANY_INFO_SUCCESS',
	updateCompanyInfoFail: 'UPDATE_COMPANY_INFO_FAIL',

	updateCompanyService: 'UPDATE_COMPANY_SERVICE',
	updateCompanyServiceSuccess: 'UPDATE_COMPANY_SERVICE_SUCCESS',
	updateCompanyServiceFail: 'UPDATE_COMPANY_SERVICE_FAIL'
}

export const userTypes = {
	updateUserInfo: 'UPDATE_USER_INFO',
	updateUserInfoSuccess: 'UPDATE_USER_INFO_SUCCESS',
	updateUserInfoFail: 'UPDATE_USER_INFO_FAIL'
}
