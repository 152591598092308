/**
 *
 * @author Trung H <trung@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */
import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { userSelectors } from '../../../reducers'

// Assets
import akupunktioLogo from '../../../assets/Akupunktioklinikka_logo.png'

// Components
import Loading from '../Loading'
import Nav from './Nav'
import Snackbar from '../../components/Snackbar'

const AuthorizedLayout = ({ children, userLoading }) => {
	const [navStatus, setNavStatus] = useState(false)
	const bookingLoading = useSelector(state => state.booking.loading)

	const location = useLocation()
	const page = location.pathname.replace(/\//, '').toLowerCase() || 'home'
	return (
		<>
			<header>
				<Nav navStatus={navStatus} onClick={() => setNavStatus(!navStatus)} />
				{!navStatus ? (
					<button
						className='btn btn--round btn--open-nav'
						onClick={() => setNavStatus(!navStatus)}
					>
						+
					</button>
				) : null}
			</header>
			<main
				className={`authorized-layout ${page}-page`}
				onClick={() => setNavStatus(false)}
			>
				{userLoading || bookingLoading ? (
					<div className='loading__page-container'>
						<div className='loading__page-container-bg'></div>
						<Loading />
					</div>
				) : null}

				<img
					className={
						'authorized-layout__bg ' +
						(page === 'home' ||
						page === 'pre-info-reminder' ||
						page === 'notification/new'
							? 'authorized-layout__bg-center'
							: 'authorized-layout__bg-up-left')
					}
					src='../../assets/bg-center.png'
					alt='web top background'
				/>
				<div className='authorized-layout__bg-mobile-logo'>
					<img src={akupunktioLogo} alt='Akupunktioklinikka' />
					<h5>AKUPUNKTIOKLINIKKA</h5>
				</div>
				{children}
				<Snackbar />
			</main>
		</>
	)
}

export default connect(state => ({
	userLoading: userSelectors.getLoading(state.user)
}))(AuthorizedLayout)
