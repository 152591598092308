/**
 *
 * @author Trung H <trung@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */
import {
	CREATE_BOOKING,
	CREATE_BOOKING_FAIL,
	CREATE_BOOKING_SUCCESS,
	CREATE_SERVICE_FAIL,
	EDIT_BOOKING,
	EDIT_BOOKING_FAIL,
	EDIT_BOOKING_STATUS,
	EDIT_BOOKING_STATUS_FAIL,
	EDIT_BOOKING_STATUS_SUCCESS,
	EDIT_BOOKING_SUCCESS,
	GET_COMPANIES,
	GET_COMPANIES_FAIL,
	GET_COMPANIES_SUCCESS,
	GET_COMPANY_SERVICES,
	GET_COMPANY_SERVICES_FAIL,
	GET_COMPANY_SERVICES_SUCCESS,
	REMOVE_BOOKING,
	REMOVE_BOOKING_FAIL,
	REMOVE_BOOKING_SUCCESS
} from '../actions/types'

const initialState = {
	companies: [],
	services: [],
	loading: false
}

export const appointmentReducer = (state = initialState, action) => {
	switch (action.type) {
		case CREATE_BOOKING_FAIL:
		case EDIT_BOOKING_FAIL:
		case EDIT_BOOKING_STATUS_FAIL:
		case GET_COMPANIES_FAIL:
		case GET_COMPANY_SERVICES_FAIL:
		case REMOVE_BOOKING_FAIL:
			return {
				...state,
				loading: false
			}
		case CREATE_BOOKING:
		case EDIT_BOOKING:
		case EDIT_BOOKING_STATUS:
		case GET_COMPANIES:
		case GET_COMPANY_SERVICES:
		case REMOVE_BOOKING:
			return {
				...state,
				loading: true
			}
		case CREATE_BOOKING_SUCCESS:
		case REMOVE_BOOKING_SUCCESS:
			return {
				...state,
				loading: false
			}
		case EDIT_BOOKING_SUCCESS:
		case EDIT_BOOKING_STATUS_SUCCESS:
			return {
				...state,
				loading: false
			}
		case GET_COMPANIES_SUCCESS:
			return {
				...state,
				companies: action.payload,
				loading: false
			}
		case GET_COMPANY_SERVICES_SUCCESS:
			return {
				...state,
				services: action.payload,
				loading: false
			}

		default:
			return state
	}
}

export const appointmentSelectors = {
	getCompanies: (state = initialState) => state.companies,
	getServices: (state = initialState) => state.services
}
