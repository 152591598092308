import { notificationTypes as types } from './types'
import { NOTIFICATION_TYPE } from '../constants'

export const showNotification = messageObj => {
	const defaultMessage = {
		type: NOTIFICATION_TYPE.info,
		message: 'Message',
		duration: 2000
	}
	return {
		type: types.showNotification,
		notification: { ...defaultMessage, ...messageObj }
	}
}
export const resetNotification = () => {
	return {
		type: types.resetNotification
	}
}
